import { FC, ReactNode } from "react";
import { FormInputValue } from "types/Form";

const Input: FC<{
  labelWidth?: string;
  inputWidth?: string;
  label?: string | ReactNode;
  value: FormInputValue;
  onChange: (value: FormInputValue) => void;
  onInput?: (value: FormInputValue) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  className?: string;
  classNameForInput?: string;
  isIncorrectValue?: boolean;
  placeholder?: string;
  type?: "text" | "number" | "email" | "password";
  disabled?: boolean;
  name: string;
  pattern?: RegExp;
  children?: ReactNode;
  isTextarea?: boolean;
  rowCount?: number;
  readonly?: boolean;
}> = ({
  labelWidth,
  label,
  value,
  onBlur,
  onFocus,
  onInput,
  onChange,
  classNameForInput,
  type = "text",
  disabled,
  isIncorrectValue,
  placeholder,
  className,
  name,
  pattern,
  children,
  inputWidth,
  isTextarea,
  rowCount,
  readonly,
}) => {
  return (
    <label
      className={`input-box ${className || ""} ${
        label ? "input-box_with-label" : ""
      }`}
      htmlFor={name}
    >
      {label && (
        <div
          className="input-box__label"
          style={{ width: `${labelWidth || "max-content"}` }}
        >
          {label}
          {isIncorrectValue && <i className="icon-cancel-circled red" />}
        </div>
      )}
      <div className="input-box__input-wrapper">
        {children && children}
        {!isTextarea && (
          <input
            name={name}
            readOnly={readonly}
            id={name}
            className={`input-box__input ${classNameForInput || ""} ${
              isIncorrectValue ? "input-box__input_error" : ""
            }`}
            style={{ maxWidth: `${inputWidth || "unset"}` }}
            value={value}
            onChange={(e) => {
              const value = e.currentTarget.value;

              if (!pattern || (pattern && pattern.test(value)) || !value) {
                onChange(value);
              }
            }}
            onInput={(e) => {
              const value = e.currentTarget.value;

              if (
                (onInput && !pattern) ||
                (onInput && pattern && pattern.test(value))
              ) {
                onInput(value);
              }
            }}
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            type={type}
            disabled={disabled}
          />
        )}
        {isTextarea && (
          <textarea
            name={name}
            id={name}
            className={`input-box__input ${classNameForInput || ""} ${
              isIncorrectValue ? "input-box__input_error" : ""
            }`}
            style={{ maxWidth: `${inputWidth || "unset"}` }}
            value={value}
            onChange={(e) => {
              const value = e.currentTarget.value;

              if (!pattern || (pattern && pattern.test(value)) || !value) {
                onChange(value);
              }
            }}
            onInput={(e) => {
              const value = e.currentTarget.value;

              if (
                (onInput && !pattern) ||
                (onInput && pattern && pattern.test(value))
              ) {
                onInput(value);
              }
            }}
            placeholder={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={disabled}
            rows={rowCount || 1}
          />
        )}
      </div>
    </label>
  );
};

export default Input;
