import { gql } from "@apollo/client";

export const FILTER_QUOTES = gql`
  query filterQuote(
    $filterType: String!
    $orderId: FilterQuoteOrderId
    $quoteId: FilterQuoteId
    $firstName: FilterQuoteFirstName
    $lastName: FilterQuoteLastName
    $company: FilterQuoteCompany
    $status: FilterQuoteStatus
    $paymentStatus: FilterQuotePaymentStatus
    $fax: FilterQuoteFax
    $email: FilterQuoteEmail
    $phone: FilterQuotePhone
    $dateOrderWasPlaced: FilterQuoteDateOrderWasPlaced
    $dateOrderWasCompleted: FilterQuoteDateOrderWasCompleted
    $withoutTax: Boolean
    $withoutTotal: Boolean
    $followUp: Boolean
    $createdAt: FilterQuoteCreatedAt
    $globalSearch: String
    $limit: Int
    $page: Int
    $sortBy: QuoteSortBy
  ) {
    filterQuote(
      filterType: $filterType
      orderId: $orderId
      quoteId: $quoteId
      firstName: $firstName
      lastName: $lastName
      company: $company
      status: $status
      paymentStatus: $paymentStatus
      fax: $fax
      email: $email
      phone: $phone
      dateOrderWasPlaced: $dateOrderWasPlaced
      dateOrderWasCompleted: $dateOrderWasCompleted
      withoutTax: $withoutTax
      withoutTotal: $withoutTotal
      followUp: $followUp
      createdAt: $createdAt
      globalSearch: $globalSearch
      limit: $limit
      page: $page
      sortBy: $sortBy
    ) {
      dataCollection {
        id
        leadSourceStatus
        status
        nextFollowUpTime
        clientContact {
          id
          firstName
          lastName
          email
          phone1
          fax
        }
        client {
          id
          firstName
          company
          email
          phone
        }
        user {
          id
        }
        supplier {
          id
          userName
          firstName
          lastName
          phone
          email
          createdAt
          updatedAt
          login
          isDeleted
        }
        quoteOrder {
          id
          paymentStatus
          dateWasPlaced
          trackingNumber1
        }
        shippingAddress {
          id
          address
        }
        billingAddress {
          id
          address
        }
        dateAssigned
        dateCompleted
        leadSource
        quoteType
        trackShippingUrl
        supplier {
          id
        }
        useInventory
        requiredDeliveryDate
        shippingTax
        quoteIdPublic
        withoutTotal
        withoutTax
        status
        note
        useAdditionalLine
        leadSourceStatus
        quoteFollowUp
        quoteFollowUpStatus
        autoFollowUpCount
        nextFollowUpTime
        callReviewStatus
        reportStartTime
        isWorkingTimeAdd
        uaClientId
        reviewInvitationUrl
        createdAt
        updatedAt
      }
      totalNumberOfItems
    }
    paymentStatuses {
      label
      value
    }
    leadSource {
      label
      value
    }
    quoteStatus {
      label
      value
    }
  }
`;

export const QUOTE_STATUS = gql`
  query quoteStatus {
    quoteStatus {
      label
      value
    }
  }
`;

export const PAYMENT_STATUSSES = gql`
  query paymentStatuses {
    paymentStatuses {
      label
      value
    }
  }
`;

export const LEAD_SOURCE = gql`
  query leadSource {
    leadSource {
      label
      value
    }
  }
`;

export const LEAD_SOURCE_STATUS = gql`
  query leadSourceStatus {
    leadSourceStatus {
      label
      value
    }
  }
`;

export const Lead_SOURCE_STATUS2 = gql`
  query configQuotesStatuses {
    configQuotesStatuses {
      id
      name
      systemName
      position
      dateCreated
      dateModified
    }
  }
`;

export const PRODUCT_OPTION_PRIZES = gql`
  query productOptionsPrices($productId: Int!) {
    productOptionsPrices(productId: $productId) {
      options
      priceTables {
        id
        name
        productPriceTableValues {
          productOption {
            id
          }
        }
      }
      priceData
    }
  }
`;

export const PRODUCT_COLLECTION_LIST = gql`
  query productCategories($requiredFields: [String]!) {
    productCategories(requiredFields: $requiredFields)
  }
`;

export const PAYMENT_TYPES = gql`
  query paymentTypes {
    paymentTypes {
      label
      value
    }
  }
`;

export const SHIPPING_METHODS = gql`
  query shippingMethods {
    shippingMethods {
      label
      value
    }
  }
`;

export const QUOTE_IMAGE_TYPES = gql`
  query quoteImageTypes {
    quoteImageTypes {
      label
      value
    }
  }
`;

export const QUOTES_LOGS_BY_USER = gql`
  query quoteLogs($quoteId: Int!, $limit: Int, $page: Int) {
    quoteLogs(quoteId: $quoteId, limit: $limit, page: $page) {
      dataCollection {
        id
        itemId
        itemType
        data
        createdAt
        updatedAt
        user {
          id
          firstName
          lastName
        }
        quote {
          id
        }
        client {
          id
        }
      }
      totalNumberOfItems
    }
  }
`;

export const QUOTE_EXPENSES_COLLECTION_QUERY = gql`
  query quoteExpensesByQuote($quoteId: Int!) {
    quoteExpensesByQuote(quoteId: $quoteId) {
      id
      value
      quote {
        id
      }
      expense {
        id
        name
      }
    }
  }
`;

export const QUOTES_FILES_COLLECTION_QUERY = gql`
  query quoteFilesByQuote($quoteId: Int!) {
    quoteFilesByQuote(quoteId: $quoteId) {
      id
      title
      comment
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
      fileUid
      fileURL
      fileName
    }
  }
`;

export const SUPPLIER_COLLECTION_QUERY = gql`
  query suppliers {
    suppliers {
      id
      roles {
        name
      }
      email
      rememberCreatedAt
      signInCount
      currentSignInAt
      lastSignInAt
      currentSignInIp
      lastSignInIp
      createdAt
      updatedAt
      login
      userName
      firstName
      lastName
      phone
      emailSignature
      isDeleted
    }
  }
`;

export const QUICK_QUOTE_LINE_COLLECTION_QUERY = gql`
  query quickQuoteLines {
    quickQuoteLines {
      id
      name
      description
      price
      count
      createdAt
      updatedAt
    }
  }
`;

export const PRODUCT_WITH_CATEGORY = gql`
  query productsWithCategory {
    productsWithCategory {
      id
      name
      description
      category
    }
  }
`;

export const QUOTE_COMMENTS_COLLECTION_QUERY = gql`
  query quoteCommentsByQuote($quoteId: Int!) {
    quoteCommentsByQuote(quoteId: $quoteId) {
      id
      text
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
    }
  }
`;

export const QUOTE_MESSAGE_COLLECTION_QUERY = gql`
  query quoteMessagesByQuote($quoteId: Int!) {
    quoteMessagesByQuote(quoteId: $quoteId) {
      id
      text
      readable
      quote {
        id
      }
      user {
        id
        userName
      }
      createdAt
      updatedAt
    }
  }
`;

export const STATE_COLLECION_QUERY = gql`
  query states {
    states {
      id
      name
      tax
    }
  }
`;
export const QUOTE_CREDITS_COLLECTION_QUERY = gql`
  query quoteCreditsByQuote($quoteId: Int!) {
    quoteCreditsByQuote(quoteId: $quoteId) {
      id
      credit
      quote {
        id
      }
    }
  }
`;

export const QUOTE_IMAGES_COLLECTION_QUERY = gql`
  query quoteImagesByQuote($quoteId: Int!) {
    quoteImagesByQuote(quoteId: $quoteId) {
      id
      quote {
        id
      }
      title
      comment
      approved
      imageType
      createdAt
      updatedAt
      imageName
      imageURL
      imageUid
    }
  }
`;

export const FILTER_COLLECTION_QUERY = gql`
  query filters {
    filters {
      id
      data
      name
      page
    }
  }
`;

export const CHOOSE_FILTER_lIST_QUERY = gql`
  query userFilters($userId: Int!, $page: String!) {
    userFilters(userId: $userId, page: $page) {
      id
      data
      page
      name
    }
  }
`;

export const QUOTE_ACTIVE_LEADS_FILTER_QUERIES = gql`
  query filterQueries($userId: Int!, $page: String!) {
    filters {
      id
      data
      name
      page
    }
    userDefaultFilter(userId: $userId, page: $page) {
      id
      data
      page
      name
    }
  }
`;
export const DOWNLOAD_PDF = gql`
  query downloadPdf($quoteId: Int!, $type: String!, $template: String!) {
    downloadPdf(quoteId: $quoteId, type: $type, template: $template) {
      file
      fileName
    }
  }
`;
export const READ_CLIENTS = gql`
  query readClients($search: String!) {
    readClients(search: $search) {
      id
      user {
        id
      }
      company
      accountName
      defaultContact {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
          client {
            id
            user {
              id
            }
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
          client {
            id
            user {
              id
            }
          }
        }
        defaultShipping
        defaultBilling
        client {
          id
          user {
            id
          }
        }
        sex
        isDelete
        birthday
      }
      accessLevelCd
      clientContacts {
        id
        firstName
        lastName
        email
        phone1
        phone2
        jobTitle
        fax
        note
        shippingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
          client {
            id
            user {
              id
            }
          }
        }
        billingAddress {
          id
          address
          city
          state
          country {
            id
            name
          }
          zip
          attentionTo
          client {
            id
            user {
              id
            }
          }
        }
        defaultShipping
        defaultBilling
        client {
          id
          user {
            id
          }
        }
        sex
        birthday
      }
      clientCreditCards {
        id
        holderName
        cardNumber
      }
      shippingAddresses {
        id
        address
        city
        state
        country {
          id
          name
        }
        zip
        attentionTo
        address2
        typeAddress
        client {
          id
          user {
            id
          }
        }
      }
    }
  }
`;
