import { FC, Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { capitalize, cloneDeep } from "lodash";
import {
  dateValuesSelect,
  getDataCollectionByType,
  getFields,
  getLabelByIdFromDataCollection,
  getNumberId,
  getTableHeading,
  getTypeLabel,
  isBooleanFilterSelectName,
  isCorrectId,
  isCorrectType,
  isEnableDeleteItems,
  numberValuesSelect,
  stringValuesSelect,
} from "./Pages/AdminDashboardHandler";
import { TabListNew } from "../../types/Tabs";
import TabsNew from "../../components/Form/El/Tabs";
import SelectCombobox from "../../components/Form/El/SelectCombobox";
import Input from "../../components/Form/El/Input";
import Select from "../../components/Form/El/Select";
import Calendar from "../../components/Form/El/Calendar";
import { regExpNumber } from "../../helpers/RegExp";
import {
  AdminDashboardFilterObjForServer,
  AdminDashboardIdsForSelect,
  AdminDashboardSelectedFilter,
  DataCollectionItem,
} from "../../types/AdminDashboard";
import { ChildrenTable, SortOrder } from "../../types/Table";
import { Params } from "../../types/Main";
import { useReactiveVar } from "@apollo/client";
import { userContextData } from "../../helpers/cache";
import { onChangeParamsCallback } from "../../helpers/Main";
import TableNew from "../../components/Table/TableNew";
import AdminDashboardDeleteItems from "./Pages/AdminDasboadrdDeleteItems";
import AdminDashboardShowItem from "./Pages/AdminDashboardShowItem";
import AdminDashboardEditItem from "./Pages/AdminDashboardEditItem";
import AdminDashboardDefault from "./Pages/AdminDashboardDefault/AdminDashboardDefault";

const AdminDashboard: FC<{}> = ({}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const path = useMemo(() => location.pathname, [location]);

  const defaultPath = useMemo(() => "/admin", []);
  const pathList = useMemo(
    () =>
      path
        .replace(`${defaultPath}`, "")
        .split("/")
        .filter((pathItem) => !!pathItem),
    [defaultPath, path],
  );

  const type = useMemo(() => pathList[0] || "", [pathList]);
  const isDashboardType = useMemo(() => type === "dashboard", [type]);
  const id = useMemo(() => pathList[1] || "", [pathList]);
  const numberId = useMemo(() => getNumberId(id), [id]);
  const action = useMemo(() => pathList[2] || "", [pathList]);
  const labelType = useMemo(() => getTypeLabel(type), [type]);
  const isMultipleDelete = useMemo(() => id === "bulk_action", [id]);
  const isEnabledSelectItems = useMemo(() => isEnableDeleteItems(type), [type]);
  const filterFields = useMemo(() => getFields(type), [type]);

  const [globalSearch, setGlobalSearch] = useState("");

  const [selectedFilter, setSelectedFilter] = useState<
    AdminDashboardSelectedFilter[]
  >([]);

  const [idsForSelect, setIdsForSelect] = useState<
    AdminDashboardIdsForSelect[]
  >([]);

  const currentTab = useMemo(() => {
    const firstPathItem = pathList[0];
    const secondPathItem = pathList[1];
    const thirdPathItem = pathList[2];

    if (!firstPathItem || (firstPathItem && isDashboardType)) {
      return firstPathItem;
    }

    if (!isNaN(+secondPathItem)) {
      return thirdPathItem;
    }

    if (isCorrectType(secondPathItem)) {
      return "";
    }

    return secondPathItem;
  }, [path]);

  const userDetails: any = useReactiveVar(userContextData);

  const defaultSortOrder = useMemo(
    (): SortOrder => ({
      columnName: "id",
      orderBy: "DESC",
    }),
    [],
  );

  const tableHeadingList = useMemo(() => getTableHeading(type), [type]);
  const [sortOrder, setSortOrder] = useState<SortOrder>(defaultSortOrder);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number | undefined>();
  const [countPages, setCountPages] = useState(0);
  const [prevFetchSettings, setPrevFetchSettings] = useState<Params>({});
  const [prevType, setPrevType] = useState<Params>({});
  const [prevId, setPrevId] = useState<Params>({ id: undefined });
  const [isLoadingCollectionList, setIsLoadingCollectionList] = useState(false);
  const [isNeedUpdateDataCollection, setIsNeedUpdateDataCollection] =
    useState(true);
  const [childrenForTable, setChildrenForTable] = useState<ChildrenTable[]>([]);
  const [dataCollection, setDataCollection] = useState<DataCollectionItem[]>(
    [],
  );
  const labelId = useMemo(
    () => getLabelByIdFromDataCollection(type, id, dataCollection),
    [id, dataCollection],
  );
  const [currentDataCollectionItem, setCurrentDataCollectionItem] = useState<
    DataCollectionItem | undefined
  >(undefined);
  const [currentChildrenForTable, setCurrentChildrenForTable] = useState<
    ChildrenTable | undefined
  >(undefined);

  // effects
  useEffect(() => {
    const countItemPerPage = parseInt(
      userDetails?.user?.userSettings?.filter(
        (count: any) => count.name === "clients_default_count",
      )?.[0]?.value,
    );

    setPageSize(countItemPerPage || 10);
  }, [userDetails]);

  useEffect(() => {
    const currentParams = {
      pageSize,
      currentPage,
      sortOrder,
    };

    onChangeParamsCallback(
      prevFetchSettings,
      currentParams,
      (newParams) => setPrevFetchSettings(newParams),
      () => {
        setIsNeedUpdateDataCollection(true);
      },
    );
  }, [pageSize, currentPage, sortOrder, prevFetchSettings]);

  useEffect(() => {
    if (isNeedUpdateDataCollection) {
      getDataCollection();
    }
  }, [isNeedUpdateDataCollection]);

  useEffect(() => {
    if (type && !(isCorrectType(type) || isDashboardType)) {
      navigate(`${defaultPath}`);
    }

    onChangeParamsCallback(
      prevType,
      { type },
      (newParams) => setPrevType(newParams),
      () => {
        setIdsForSelect([]);
        setSelectedFilter([]);

        if (sortOrder !== defaultSortOrder) {
          setSortOrder(defaultSortOrder);
          return;
        }

        setIsNeedUpdateDataCollection(true);
      },
    );
  }, [type, defaultPath]);

  useEffect(() => {
    onChangeParamsCallback(
      prevId,
      { id: numberId },
      (newParams) => setPrevId(newParams),
      () => {
        if (
          prevId.id &&
          typeof prevId.id === "number" &&
          !isNaN(prevId.id) &&
          isNaN(numberId)
        ) {
          setSelectedFilter([]);
          setIsNeedUpdateDataCollection(true);
        }
      },
    );
  }, [numberId]);

  const childrenTableWithHelperButtonButton: ChildrenTable[] = useMemo(() => {
    const helperButton = (id: number) => {
      return (
        <div className="btn-group">
          <button
            className="btn btn-mini btn-blue"
            onClick={() => {
              navigate(`${defaultPath}/${type}/${id}/info`);
            }}
          >
            <i className="icon-info-circled icon-medium" />
          </button>
          <button
            className="btn btn-mini btn-success"
            onClick={() => {
              navigate(`${defaultPath}/${type}/${id}/edit`);
            }}
          >
            <i className="icon-pencil icon-medium" />
          </button>
          {isEnabledSelectItems && (
            <button
              className="btn btn-mini btn-warning"
              onClick={() => {
                navigate(`${defaultPath}/${type}/${id}/delete`);
              }}
            >
              <i className="icon-cancel icon-medium" />
            </button>
          )}
        </div>
      );
    };

    const getUsersString = (users: string) => {
      const parseUsers = JSON.parse(users);

      return (
        <Fragment>
          {parseUsers.map(
            (user: { id: number; name: string }, index: number) => (
              <Fragment key={`user-${id}-${index}`}>
                {!!index && ", "}{" "}
                <Link to={`${defaultPath}/user/${user.id || 0}`}>
                  {user.name || "-"}
                </Link>
              </Fragment>
            ),
          )}
        </Fragment>
      );
    };

    return childrenForTable.map((childrenForTableItem) => {
      return {
        id: childrenForTableItem.id,
        row: {
          ...childrenForTableItem.row,
          user:
            typeof childrenForTableItem.row.user === "string" ? (
              <Link
                to={`${defaultPath}/user/${
                  JSON.parse(childrenForTableItem.row.user)?.id || 0
                }`}
              >
                {JSON.parse(childrenForTableItem.row.user)?.name || "-"}
              </Link>
            ) : (
              ""
            ),
          users:
            typeof childrenForTableItem.row.users === "string"
              ? getUsersString(childrenForTableItem.row.users)
              : "-",
          helper: helperButton(childrenForTableItem.id),
        },
        expandRow: childrenForTableItem.expandRow,
      };
    });
  }, [childrenForTable, isEnabledSelectItems]);

  useEffect(() => {
    if (
      id &&
      isCorrectId(numberId) &&
      (!currentDataCollectionItem ||
        currentDataCollectionItem?.id !== numberId) &&
      !isNeedUpdateDataCollection
    ) {
      setSelectedFilter([
        {
          label: "Id",
          name: "id",
          select: "number",
          value: { simple: id.toString(), from: null, to: null },
          type: "number",
        },
      ]);
      setIsNeedUpdateDataCollection(true);
    }
  }, [numberId, action]);

  const newCurrentDataCollectionItem = useMemo(() => {
    return dataCollection.find((el) => numberId === el.id);
  }, [dataCollection, numberId]);

  useEffect(() => {
    if (
      id &&
      isCorrectId(numberId) &&
      !newCurrentDataCollectionItem &&
      !isNeedUpdateDataCollection
    ) {
      setSelectedFilter([
        {
          label: "Id",
          name: "id",
          select: "number",
          value: { simple: id.toString(), from: null, to: null },
          type: "number",
        },
      ]);
      setIsNeedUpdateDataCollection(true);
    }
  }, [numberId, newCurrentDataCollectionItem, isNeedUpdateDataCollection]);

  useEffect(() => {
    if (!dataCollection.length) return;

    setCurrentDataCollectionItem(
      !!newCurrentDataCollectionItem &&
        typeof newCurrentDataCollectionItem === "object"
        ? newCurrentDataCollectionItem
        : undefined,
    );

    const transformedChildrenForTableItem =
      childrenTableWithHelperButtonButton.find((el) => numberId === el.id);

    setCurrentChildrenForTable(
      !!transformedChildrenForTableItem &&
        typeof transformedChildrenForTableItem === "object"
        ? transformedChildrenForTableItem
        : undefined,
    );
  }, [
    dataCollection,
    newCurrentDataCollectionItem,
    childrenTableWithHelperButtonButton,
  ]);

  useEffect(() => {
    const index = selectedFilter.findIndex(
      (el) => el.name === "id" && el.select === "number",
    );
    if (index !== -1) {
      selectedFilter.splice(index, 1);
    }

    if (!id && index !== -1) {
      setSelectedFilter(selectedFilter);
      setIsNeedUpdateDataCollection(true);
    }
  }, [id]);

  const title = useMemo(() => {
    if (!type || isDashboardType) {
      return "Site administration";
    }

    if (type && (!id || id === "list")) {
      return `List of ${labelType}`;
    }

    if (type && id === "new") {
      return `New ${labelType}`;
    }

    if (type && isMultipleDelete) {
      return `Delete ${labelType}`;
    }

    if (
      type &&
      isCorrectId(numberId) &&
      !isMultipleDelete &&
      (!action || action === "info")
    ) {
      return `Detail for ${labelType} ${capitalize(labelId)}`;
    }

    if (type && isCorrectId(numberId) && action) {
      return `${capitalize(action)} ${
        isMultipleDelete
          ? labelType
          : "for " + labelType + " " + capitalize(labelId)
      }`;
    }
  }, [labelType, type, id, numberId, action, isMultipleDelete, labelId]);

  const breadcrumbsList = useMemo(() => {
    let currentPath = defaultPath;

    const linkList = [
      {
        label: "Dashboard",
        path: defaultPath,
      },
    ];

    if (type) {
      linkList.push({
        path: `${currentPath}/${type}`,
        label: capitalize(labelType),
      });

      currentPath = currentPath.concat(`/${type}`);
    }

    if (isCorrectId(numberId)) {
      linkList.push({
        path: `${currentPath}/${id}`,
        label: capitalize(labelId),
      });
    }

    if (id) {
      currentPath = currentPath.concat(`/${id}`);
    }

    if (action) {
      linkList.push({
        path: `${currentPath}/${action}`,
        label: capitalize(action),
      });
    }

    return linkList;
  }, [type, labelType, action, id, numberId, defaultPath, labelId]);

  const tabsList = useMemo(
    (): TabListNew => [
      {
        name: "dashboard",
        iconName: "icon-home",
        label: "Dashboard",
        hidden: !!type && !isDashboardType,
      },
      {
        name: `list`,
        iconName: "icon-th-list",
        label: "List",
        hidden: !(type && !isDashboardType) || isCorrectId(numberId),
      },
      {
        name: `new`,
        iconName: "icon-plus",
        label: "Add New",
        hidden: !(type && !isDashboardType) || isCorrectId(numberId),
      },
      {
        name: `info`,
        iconName: "icon-info-circled",
        label: "Show",
        hidden: !isCorrectId(numberId),
      },
      {
        name: `edit`,
        iconName: "icon-pencil",
        label: "Edit",
        hidden: !isCorrectId(numberId),
      },
      {
        name: id === "bulk_action" ? "bulk_action" : "delete",
        iconName: "icon-trash-empty",
        label: "Delete",
        hidden: !isCorrectId(numberId) && id !== "bulk_action",
      },
    ],
    [id, type, numberId],
  );

  const displayedSelectedFields = useMemo(() => {
    let fields: string[] = [];

    filterFields.forEach((filterField) => {
      fields.push(
        `${
          selectedFilter.some((el) => el.label === filterField.label)
            ? "\u2705 "
            : ""
        }${filterField.label}`,
      );
    });
    return fields;
  }, [filterFields, selectedFilter]);

  const selectedValues = useMemo(
    () => ({
      number: numberValuesSelect,
      string: stringValuesSelect,
      date: dateValuesSelect,
    }),
    [],
  );

  const toggleSelectedFilterField = useCallback(
    (name: AdminDashboardSelectedFilter["name"], isLabel?: boolean) => {
      const tmpSelectedFilter = cloneDeep(selectedFilter);
      let currentLabel = "";
      let filterField;
      if (isLabel) {
        currentLabel = name.replace("\u2705 ", "");
      }
      filterField = filterFields.find((el) => {
        if (isLabel) {
          return el.label === currentLabel;
        }
        return el.name === name;
      });

      const searchIndex = tmpSelectedFilter.findIndex((el) => {
        if (isLabel) {
          return el.label === currentLabel;
        }
        return el.name === name;
      });

      const getSelectedFirstValue = (
        type: AdminDashboardSelectedFilter["type"],
      ) => selectedValues[type][0].id;

      if (searchIndex === -1 && filterField) {
        tmpSelectedFilter.push({
          label: isLabel ? currentLabel : filterField.label,
          name: filterField.name,
          select: getSelectedFirstValue(filterField.type),
          value: {
            simple: "",
            from: null,
            to: null,
          },
          type: filterField.type,
        });
      }

      if (searchIndex !== -1) {
        tmpSelectedFilter.splice(searchIndex, 1);
      }

      setSelectedFilter(tmpSelectedFilter);
    },
    [selectedFilter, filterFields, selectedValues],
  );

  const onChangeSelectedFilter = useCallback(
    (
      name: AdminDashboardSelectedFilter["name"],
      typeValue: "select" | "value",
      value: string | boolean,
      subtype?: "from" | "to",
    ) => {
      const editableSelectedFilterIndex = selectedFilter.findIndex(
        (el) => el.name === name,
      );

      if (editableSelectedFilterIndex === -1) return;

      const tmpSelectedFilter = cloneDeep(selectedFilter);

      const tmpEditableSelectedFilter =
        tmpSelectedFilter[editableSelectedFilterIndex];

      if (typeValue === "select" && typeof value === "string") {
        tmpEditableSelectedFilter.select = value;
        tmpEditableSelectedFilter.value = {
          simple: "",
          from: null,
          to: null,
        };
      }

      if (
        typeValue === "select" &&
        typeof value === "string" &&
        isBooleanFilterSelectName(value)
      ) {
        tmpEditableSelectedFilter.value = {
          ...tmpEditableSelectedFilter.value,
          simple: true,
        };
      }

      if (typeValue === "value" && !subtype) {
        tmpEditableSelectedFilter.value = {
          ...tmpEditableSelectedFilter.value,
          simple: value,
        };
      }

      if (typeValue === "value" && subtype && typeof value !== "boolean") {
        tmpEditableSelectedFilter.value = {
          ...tmpEditableSelectedFilter.value,
          [subtype]: value || null,
        };
      }

      setSelectedFilter([...tmpSelectedFilter]);
    },
    [selectedFilter],
  );

  const getDataCollection = useCallback(async () => {
    if (!pageSize) {
      setIsNeedUpdateDataCollection(false);
      return;
    }

    const selectedFilterObjForServer: AdminDashboardFilterObjForServer = {};

    const getNumberValueIfNeeded = (
      value: string,
      typeSelect: AdminDashboardSelectedFilter["type"],
    ) => {
      if (typeSelect === "number") {
        return parseInt(value);
      }

      return value;
    };

    selectedFilter.forEach((selectedFilterItem) => {
      if (isBooleanFilterSelectName(selectedFilterItem.select)) {
        selectedFilterObjForServer[selectedFilterItem.name] = {
          [selectedFilterItem.select]: true,
        };

        return;
      }

      if (selectedFilterItem.select === "between") {
        selectedFilterObjForServer[selectedFilterItem.name] = {
          [selectedFilterItem.select]: {
            from: selectedFilterItem.value.from
              ? getNumberValueIfNeeded(
                  selectedFilterItem.value.from,
                  selectedFilterItem.type,
                )
              : undefined,
            to: selectedFilterItem.value.to
              ? getNumberValueIfNeeded(
                  selectedFilterItem.value.to,
                  selectedFilterItem.type,
                )
              : undefined,
          },
        };
        return;
      }

      if (selectedFilterItem.select === "date") {
        selectedFilterObjForServer[selectedFilterItem.name] = {
          [selectedFilterItem.select]:
            selectedFilterItem.value.from || undefined,
        };

        return;
      }

      selectedFilterObjForServer[selectedFilterItem.name] = {
        [selectedFilterItem.select]: selectedFilterItem.value.simple
          ? getNumberValueIfNeeded(
              selectedFilterItem.value.simple.toString(),
              selectedFilterItem.type,
            )
          : undefined,
      };
    });

    selectedFilterObjForServer.globalSearch = globalSearch || "";

    setIsLoadingCollectionList(true);

    await getDataCollectionByType(
      type,
      selectedFilterObjForServer,
      pageSize,
      currentPage,
      sortOrder,
    )
      .then((res) => {
        const { childrenForTable, countPages, dataCollection } = res;

        setCountPages(countPages);
        setChildrenForTable(childrenForTable);
        setDataCollection(dataCollection);
      })
      .finally(() => {
        setIsLoadingCollectionList(false);

        if (isNeedUpdateDataCollection) {
          setIsNeedUpdateDataCollection(false);
        }
      });
  }, [
    selectedFilter,
    globalSearch,
    type,
    pageSize,
    currentPage,
    sortOrder,
    isNeedUpdateDataCollection,
  ]);

  const idsForSelectOnlyNum = useMemo(
    () => idsForSelect.map((el) => el.id),
    [idsForSelect],
  );

  const onSetSelectedIds = useCallback(
    (ids: number[]) => {
      const tmpIdsForSelect = cloneDeep(idsForSelect);
      ids.forEach((id) => {
        const existedIdIndex = tmpIdsForSelect.findIndex((el) => el.id === id);

        if (existedIdIndex !== -1) {
          tmpIdsForSelect.splice(existedIdIndex, 1);
          return;
        }

        tmpIdsForSelect.push({
          id: id,
          label: getLabelByIdFromDataCollection(type, id, dataCollection),
        });
      });

      setIdsForSelect(tmpIdsForSelect);
    },
    [idsForSelect, dataCollection, type],
  );

  return (
    <section className="layout-content admin-dashboard">
      <div className="admin-dashboard__title">{title}</div>
      <div className="admin-dashboard__breadcrumbs">
        {breadcrumbsList.map((breadcrumbsItem, index) => {
          return (
            <Fragment key={`breadcrumb-link-${breadcrumbsItem.path}-${index}`}>
              {!!index && " / "}
              <Link to={breadcrumbsItem.path}>{breadcrumbsItem.label}</Link>
            </Fragment>
          );
        })}
      </div>
      <TabsNew
        tabs={tabsList}
        currentTab={currentTab}
        defaultPath={`${defaultPath}${
          isDashboardType || !type ? "" : "/" + type
        }${numberId ? "/" + numberId : ""}`}
      >
        {{
          list: (
            <div>
              {(!!filterFields.length || isEnabledSelectItems) && (
                <div className="admin-dashboard__additional-buttons">
                  {!!filterFields.length && (
                    <SelectCombobox
                      displayValue={<span>Add filter</span>}
                      value={displayedSelectedFields[0]}
                      onChange={(value) => {
                        toggleSelectedFilterField(value, true);
                      }}
                      selectOptions={displayedSelectedFields}
                      name="filter-table"
                      color="transparent"
                      selectWidth="max-content"
                    />
                  )}

                  {isEnabledSelectItems && (
                    <SelectCombobox
                      displayValue={<span>Selected Items</span>}
                      value={`Delete selected ${labelType || "items"}`}
                      onChange={(value) => {
                        const lowerCaseValue = value.toLowerCase();
                        if (lowerCaseValue.includes("delete")) {
                          navigate(`${defaultPath}/${type}/bulk_action`);
                          return;
                        }

                        if (
                          lowerCaseValue.includes("clear all selected items")
                        ) {
                          setIdsForSelect([]);
                        }
                      }}
                      selectOptions={[
                        `Delete selected ${labelType || "items"}`,
                        "Clear all selected items",
                      ]}
                      name="delete-items"
                      color="transparent"
                      selectWidth="max-content"
                    />
                  )}
                </div>
              )}
              {!!filterFields.length && (
                <div className="admin-dashboard__filter-wrapper">
                  {!!selectedFilter.length && (
                    <div className="admin-dashboard__filter-field-list">
                      {selectedFilter.map((selectedFilterItem) => (
                        <div
                          className="admin-dashboard__filter-field-item"
                          key={`filter-field-item-${selectedFilterItem.name}-${selectedFilterItem.type}`}
                        >
                          <button
                            className="btn btn-blue btn-mini select-item"
                            onClick={() => {
                              toggleSelectedFilterField(
                                selectedFilterItem.name,
                              );
                            }}
                          >
                            <i className="icon-trash-empty" />{" "}
                            {capitalize(selectedFilterItem.label)}
                          </button>
                          <Select
                            value={selectedFilterItem.select}
                            optionsGroups={[
                              {
                                options:
                                  selectedValues[selectedFilterItem.type],
                              },
                            ]}
                            color="white"
                            onChange={(value) => {
                              if (typeof value === "string") {
                                onChangeSelectedFilter(
                                  selectedFilterItem.name,
                                  "select",
                                  value,
                                );
                              }
                            }}
                            name={`admin-dashboard__filter-field-item-select-${selectedFilterItem.name}`}
                            className="select-item"
                          />
                          <div className="select-item">
                            {selectedFilterItem.type !== "date" &&
                              selectedFilterItem.select !== "between" &&
                              !isBooleanFilterSelectName(
                                selectedFilterItem.select,
                              ) && (
                                <Input
                                  value={selectedFilterItem.value.simple.toString()}
                                  onChange={(value) => {
                                    if (typeof value === "string") {
                                      onChangeSelectedFilter(
                                        selectedFilterItem.name,
                                        "value",
                                        value,
                                      );
                                    }
                                  }}
                                  name={`filter-table-${selectedFilterItem.name}-${selectedFilterItem.select}`}
                                  pattern={
                                    selectedFilterItem.type === "number"
                                      ? regExpNumber
                                      : undefined
                                  }
                                />
                              )}
                            {selectedFilterItem.type === "date" &&
                              !isBooleanFilterSelectName(
                                selectedFilterItem.select,
                              ) && (
                                <Calendar
                                  inputWidth="200px"
                                  name={`filter-table-${selectedFilterItem.name}-${selectedFilterItem.select}`}
                                  startDate={selectedFilterItem.value.from}
                                  endDate={selectedFilterItem.value.to}
                                  onStartDateChange={(value) => {
                                    if (value) {
                                      onChangeSelectedFilter(
                                        selectedFilterItem.name,
                                        "value",
                                        value,
                                        "from",
                                      );
                                    }
                                  }}
                                  onEndDateChange={(value) => {
                                    if (value) {
                                      onChangeSelectedFilter(
                                        selectedFilterItem.name,
                                        "value",
                                        value,
                                        "to",
                                      );
                                    }
                                  }}
                                  type={
                                    selectedFilterItem.select === "between"
                                      ? "range"
                                      : "default"
                                  }
                                />
                              )}
                            {selectedFilterItem.type === "number" &&
                              selectedFilterItem.select === "between" && (
                                <div className="select-item-wrapper-column select-item">
                                  <Input
                                    value={
                                      selectedFilterItem.value.from?.toString() ||
                                      ""
                                    }
                                    onChange={(value) => {
                                      if (typeof value === "string") {
                                        onChangeSelectedFilter(
                                          selectedFilterItem.name,
                                          "value",
                                          value,
                                          "from",
                                        );
                                      }
                                    }}
                                    name={`filter-table-${selectedFilterItem.name}-between-number-from`}
                                    pattern={regExpNumber}
                                  />
                                  <Input
                                    value={
                                      selectedFilterItem.value.to?.toString() ||
                                      ""
                                    }
                                    onChange={(value) => {
                                      if (typeof value === "string") {
                                        onChangeSelectedFilter(
                                          selectedFilterItem.name,
                                          "value",
                                          value,
                                          "to",
                                        );
                                      }
                                    }}
                                    name={`filter-table-${selectedFilterItem.name}-between-number-to`}
                                    pattern={regExpNumber}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      ))}
                      <hr />
                    </div>
                  )}
                  <div className="admin-dashboard__filter-search-refresh-block">
                    <Input
                      value={globalSearch}
                      onChange={(value) => {
                        if (typeof value === "string") {
                          setGlobalSearch(value);
                        }
                      }}
                      name="global-search"
                      placeholder="Filter"
                      className="admin-dashboard__filter-search"
                    />
                    <button
                      className="btn btn-small btn-blue"
                      onClick={() => {
                        setIsNeedUpdateDataCollection(true);
                      }}
                    >
                      <i className="icon-arrows-cw" /> Refresh
                    </button>
                  </div>
                </div>
              )}
              <TableNew
                heading={tableHeadingList}
                loading={isLoadingCollectionList}
                children={childrenTableWithHelperButtonButton}
                currentPage={currentPage}
                countPages={countPages}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
                currentItemsPerPage={pageSize}
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
                setIsNeedUpdateTableData={() => {
                  setIsNeedUpdateDataCollection(true);
                }}
                isEnabledSelectItems={isEnabledSelectItems}
                selectedIds={idsForSelectOnlyNum}
                setSelectedIds={onSetSelectedIds}
              />
            </div>
          ),
          [`${id === "bulk_action" ? "bulk_action" : "delete"}`]: (
            <AdminDashboardDeleteItems
              idsForSelect={idsForSelect}
              setIsNeedUpdateDataCollection={() => {
                setIsNeedUpdateDataCollection(true);
              }}
              clearIdsSelect={() => {
                setIdsForSelect([]);
              }}
              setIdsForSelect={setIdsForSelect}
              type={type}
              defaultPath={defaultPath}
              currentId={numberId}
              currentDataCollectionItem={currentDataCollectionItem}
            />
          ),
          info: (
            <AdminDashboardShowItem
              fields={filterFields}
              currentChildrenForTable={currentChildrenForTable}
            />
          ),
          new: (
            <AdminDashboardEditItem
              type={type}
              defaultPath={defaultPath}
              setIsNeedUpdate={() => {
                setIsNeedUpdateDataCollection(true);
              }}
              loadingDataCollection={isLoadingCollectionList}
            />
          ),
          edit: (
            <AdminDashboardEditItem
              type={type}
              currentId={numberId}
              defaultPath={defaultPath}
              setIsNeedUpdate={() => {
                setIsNeedUpdateDataCollection(true);
              }}
              currentDataCollectionItem={currentDataCollectionItem}
              loadingDataCollection={isLoadingCollectionList}
            />
          ),
          dashboard: <AdminDashboardDefault />,
        }}
      </TabsNew>
    </section>
  );
};

export default AdminDashboard;
