import { QuoteEditData } from "../../../../types/Quote";
import { EditClientDataShippingAddress } from "../../../../types/Client";
import { cloneDeep } from "lodash";
import { ProductEditData } from "../../../../types/Product";
import { dateFormatter } from "helpers/utils";

export function getAddressInfo (clientAddress: EditClientDataShippingAddress | null, typeAddress: EditClientDataShippingAddress['typeAddress'])  {
  console.log(clientAddress)
  if (!clientAddress) {
    return null
  }

  const isEmptyAddress = (address: EditClientDataShippingAddress) =>  Object.entries(address).every(([key, val]) => key === 'countryId' || key === 'typeAddress' || !val)

  if (!isEmptyAddress(clientAddress)) {
    return {
      ...clientAddress,
      typeAddress
    }
  }

  return null
}

export function editQuoteOrderSetObjForSending(data: QuoteEditData["order"]) {
  const { clientCreditCardId } = data;
  const currentData = {
    ...data,
    clientCreditCardId: clientCreditCardId ? clientCreditCardId : null,
  }
  
  return currentData
}

export function editQuoteSetObjForSending(data: QuoteEditData) {
  const {
    id,
    client,
    status,
    leadSourceStatus,
    leadSource,
    shippingTax,
    withoutTax,
    useAdditionalLine,
    userId,
    note,
    withoutTotal,
    order,
    orderProducts,
    useInventory,
    requiredDeliveryDate,
    supplierId,
    quoteAdditionalRows,
  } = data;

  const compactObject = (data: any) => {
    if (typeof data !== "object") {
      return data;
    }

    return Object?.keys(data)?.reduce(function (accumulator, key) {
      const isObject = typeof data[key] === "object";
      const value: any = isObject ? compactObject(data[key]) : data[key];
      const isEmptyObject = isObject && !Object.keys(value).length;
      if (value === undefined || isEmptyObject) {
        return accumulator;
      }

      return Object.assign(accumulator, { [key]: value });
    }, {});
  };

  const getProductNumber = (count: string | null | number) => {
    if (typeof count === "number") {
      return count;
    }

    if (typeof count === "string" && !isNaN(+count)) {
      return +count;
    }

    return null;
  };

  const getOrderProducts = (orderProductsArr: ProductEditData[]) => {
    const reverseOrderProducts = cloneDeep(orderProductsArr).reverse();
    const removeEmptyProduct: ProductEditData[] = [];
    let isNeedDelete = true;

    const isEmptyObj = (productVal: ProductEditData) =>  Object.entries(productVal).every(([key, val]) => key === 'id' || key === 'isNewProduct' || !val)

    reverseOrderProducts.reduce(
      (accumulator, currentValue, index) => {
        if (isNeedDelete) {
          isNeedDelete = isEmptyObj(currentValue) &&
              reverseOrderProducts[index + 1] &&
              isEmptyObj(reverseOrderProducts[index + 1]);
        }

        if (
            isNeedDelete
        ) {
          return accumulator;
        }
        accumulator.push(currentValue);
        return accumulator;
      },
      removeEmptyProduct,
    );

    if (removeEmptyProduct.length && isEmptyObj(removeEmptyProduct[0])) {
      removeEmptyProduct.splice(0, 1)
    }

    return cloneDeep(removeEmptyProduct).reverse().map((el) => {
      const trimmedDescription = el.description || "";

      return {
        id: el.id,
        description: trimmedDescription || '',
        priceTableId: el.priceTableId || null,
        productId: el.productId || null,
        productOptionString: `${el.productOptionString}` || null,
        quantity: getProductNumber(el.quantity),
        unitPrice: getProductNumber(el.unitPrice),
      };
    })
  };

  /*const getAddresses = (clientShippingAddresses: EditClientDataShippingAddress, clientBillingAddresses: EditClientDataShippingAddress) => {
    const addressesArray:Partial<EditClientDataShippingAddress>[] = []
    const isEmptyAddress = (address: EditClientDataShippingAddress) =>  Object.entries(address).every(([key, val]) => key === 'countryId' || key === 'typeAddress' || !val)
    const isEmptyClientShippingAddresses = !clientShippingAddresses.id && isEmptyAddress(clientShippingAddresses)
    const isEmptyClientBillingAddresses = !clientBillingAddresses.id && isEmptyAddress(clientBillingAddresses)

    if (!isEmptyClientShippingAddresses) {
      addressesArray.push(getClientAddress(clientShippingAddresses))
    }

    if (!isEmptyClientBillingAddresses) {
      addressesArray.push(getClientAddress(clientBillingAddresses))
    }

    return addressesArray
  }*/

  const dataForUpdate = {
    id,
    leadSource,
    userId,
    withoutTotal,
    withoutTax,
    useAdditionalLine,
    status,
    shippingTax,
    note,
    leadSourceStatus,
    client: {
      accessLevelCd: client.accessLevelCd,
      accountName: client.accountName,
      clientContact: client.clientContacts,
      shippingAddress: getAddressInfo(client.clientShippingAddresses, 'Shipping'),
      billingAddress: getAddressInfo(client.clientBillingAddresses, 'Billing'),
      company: client.company,
      id: client.id,
      industryTag: client.industryTag ? client.industryTag : 1,
      userId: client.userId,
      webAdress: client.webAddress,
    },
    orderProducts: getOrderProducts(orderProducts),
    additionalQuoteLine: quoteAdditionalRows
      ? quoteAdditionalRows.map((el) => ({
          id: el.id || undefined,
          description: el.description,
        }))
      : undefined,
    useInventory: useInventory || false,
    requiredDeliveryDate,
    supplierId: supplierId || null,
    order: editQuoteOrderSetObjForSending(order),
  };

  return dataForUpdate;
}
