import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { EDIT_CLIENT_QUERIES } from "../../Services/Queries/EditClientQuery";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import { Params } from "../../../../types/Main";
import {
  ClearEditClientData,
  EditClientDataClientContact,
  EditClientDataCreditCard,
  EditClientDataShippingAddress,
} from "../../../../types/Client";
import HeaderWithReactiveValueAndSaveBtn from "../../../../components/Header/HeaderWithReactiveValueAndSaveBtn";
import TabsNew from "../../../../components/Form/El/Tabs";
import { TabListNew } from "../../../../types/Tabs";
import EditClientLogsNew from "./EditClientLogsNew";
import EditClientHistoryNew from "./EditClientHistoryNew";
import EditClientClientInfoNew from "./EditClientClientInfoNew";
import {
  CLIENT_MASS_UPDATE,
  DELETE_CLIENT,
  GRANT_TEMPORARY_ACCESS,
} from "../../Services/Mutations/Mutation";
import EditClientAddressNew from "./EditClientAddressNew";
import EditClientListOfCardsNew from "./EditClientListOfCardsNew";
import { LoadingDesign } from "../../../../helpers";
import { isValidEmail, isValidNonEmpty } from "../../../../helpers/Validation";
import ClientDeleteModal from "./ClientDeleteModal";

const EditClientNew: FC<{ clientId?: number }> = ({ clientId }) => {
  const userDetails: any = useReactiveVar(userContextData);
  const [prevParams, setPrevParams] = useState<Params>({});
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [currentUserId, setCurrentUserId] = useState();
  const navigateTo = useNavigate();

  const clearClientContact = useMemo(
    (): EditClientDataClientContact => ({
      id: undefined,
      firstName: "",
      lastName: "",
      email: "",
      phone1: "",
      phone2: "",
      fax: "",
      jobTitle: "",
      birthday: null,
      sex: 0,
      note: "",
    }),
    [],
  );

  const clearCreditCard = useMemo(
    (): EditClientDataCreditCard => ({
      id: undefined,
      holderName: "",
      expirationYear: "",
      expirationMonth: "",
      cardNumber: "",
      verificationNumber: "",
    }),
    [],
  );

  const clearShippingAddress = useMemo(
    (): EditClientDataShippingAddress => ({
      id: undefined,
      typeAddress: "Shipping",
      address: "",
      address2: "",
      city: "",
      state: "",
      countryId: undefined,
      zip: "",
      createBothAddress: false,
      attentionTo: "",
    }),
    [],
  );

  const clearClientEditData = useMemo(
    () => ({
      accessLevelCd: 0,
      userId: currentUserId || 0,
      accountName: "",
      company: "",
      industryTag: undefined,
      webAdress: "",
      clientContacts: [],
      clientShippingAddresses: [],
      clientCreditCards: [],
    }),
    [currentUserId],
  );

  const [isLoadingClient, setIsLoadingClient] = useState(false);
  const [isLoadingUpdateClient, setIsLoadingUpdateClient] = useState(false);
  const [isLoadingDeleteClient, setIsLoadingDeleteClient] = useState(false);
  const [deleteClientId, setDeleteClientd] = useState(0);
  const [client, setClient] = useState<any>();
  const [clientEditData, setClientEditData] =
    useState<ClearEditClientData>(clearClientEditData);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentClientContactId, setCurrentClientContactId] = useState(0);
  const [defaultClientContactId, setDefaultClientContactId] = useState(0);
  const [defaultShippingAddressId, setDefaultShippingAddressId] = useState(0);
  const [defaultBillingAddressId, setDefaultBillingAddressId] = useState(0);
  const [currentShippingAddressesId, setCurrentShippingAddressesId] =
    useState(0);
  const [currentCreditCardId, setCurrentCreditCardId] = useState(0);
  const [isNeedUpdateClient, setIsNeedUpdateClient] = useState(false);
  const [isLoadingGrantTemporaryAccess, setIsLoadingGrantTemporaryAccess] =
    useState(false);

  const { pathname } = useLocation();
  const currentTab = useMemo(() => pathname.split("/")[3], [pathname]);

  useEffect(() => {
    if (clientId) {
      setIsNeedUpdateClient(true);
    }

    if (!clientId && client) {
      setClient(undefined);
    }
  }, [clientId]);

  const moduleAccess = useMemo(
    () =>
      userDetails?.user?.accessControlStructure?.modules?.client?.tabs?.[
        clientId ? "editClient" : "newClient"
      ],
    [userDetails, clientId],
  );

  const tabsAccess = useMemo(() => moduleAccess?.tabs, [moduleAccess]);
  const buttonsAccess = useMemo(() => moduleAccess?.buttons, [moduleAccess]);

  const tabClientInfoAccess = useMemo(
    () => tabsAccess?.clientInfo,
    [tabsAccess],
  );
  const tabAddressAccess = useMemo(() => tabsAccess?.address, [tabsAccess]);
  const tabListOfCardsAccess = useMemo(
    () => tabsAccess?.listOfCard,
    [tabsAccess],
  );
  const tabHistoryAccess = useMemo(() => tabsAccess?.history, [tabsAccess]);
  const tabLogsAccess = useMemo(() => tabsAccess?.logs, [tabsAccess]);
  const tabNewQuoteAccess = useMemo(() => tabsAccess?.newQuote, [tabsAccess]);

  const tabsList = useMemo(
    (): TabListNew => [
      {
        label: "Client Info",
        iconName: "icon-info-circled text-[#69AA46]",
        name: "client-info",
        hidden: !tabClientInfoAccess,
      },
      {
        label: "Address",
        iconName: "icon-truck text-[#C6699F]",
        name: `${clientId ? "edit-client" : "new-client"}-address`,
        hidden: !tabAddressAccess,
      },
      {
        label: "List Of Cards",
        iconName: "icon-credit-card text-[#478FCA]",
        name: "list-of-cards",
        hidden: !tabListOfCardsAccess,
      },
      {
        label: "History",
        iconName: "",
        name: "history",
        hidden: !tabHistoryAccess || !clientId,
      },
      {
        label: "Logs",
        iconName: "icon-list text-[#69AA46]",
        name: "logs",
        hidden: !tabLogsAccess || !clientId,
      },
      {
        label: "New Quote",
        name: "newQuote",
        onClickWithoutNavigate: () => {
          navigateTo(`/quotes/new_quotes/quote_info`, {
            state: {
              clientId,
            },
          });
        },
        hidden: !tabNewQuoteAccess || !clientId,
      },
    ],
    [
      tabLogsAccess,
      tabHistoryAccess,
      tabListOfCardsAccess,
      tabAddressAccess,
      tabClientInfoAccess,
      clientId,
      tabNewQuoteAccess,
    ],
  );

  const [deleteClient] = useMutation(DELETE_CLIENT, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: () => {
      clearSearchParams();
      setNotification([
        {
          type: "SUCCESS",
          message: "Client deleted successfully.",
        },
      ]);
    },
  });

  const [fetchClient] = useLazyQuery(EDIT_CLIENT_QUERIES, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      clearSearchParams();
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const [grantTemporaryAccess] = useMutation(GRANT_TEMPORARY_ACCESS, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const [updateClientModule] = useMutation(CLIENT_MASS_UPDATE, {
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
    onCompleted: () => {
      setNotification([
        {
          type: "SUCCESS",
          message: "Client Info saved successfully.",
        },
      ]);
    },
  });

  useEffect(() => {
    const currentId = userDetails?.user?.id;

    if (currentId && !currentUserId) {
      setCurrentUserId(currentId);
    }
  }, [userDetails]);

  useEffect(() => {
    if (currentUserId && !client) {
      setClientEditData({
        ...clearClientEditData,
      });
    }
  }, [currentUserId]);

  useEffect(() => {
    if (client && !clientId) {
      setClient(undefined);
    }
  }, [clientId, client]);

  useEffect(() => {
    const currentParams = { clientId };
    onChangeParamsCallback(
      prevParams,
      currentParams,
      (newParams) => setPrevParams(newParams),
      () => {
        if (typeof clientId === "number") {
          setIsNeedUpdateClient(true);
        }
      },
    );
  }, [clientId, prevParams]);

  useEffect(() => {
    if (isNeedUpdateClient) {
      getClient();
    }
  }, [isNeedUpdateClient]);

  useEffect(() => {
    let currentClientData: ClearEditClientData = { ...clearClientEditData };

    if ((!client && clientId) || isLoadingClient) {
      return;
    }

    const needUpdateData = client && clientId;

    if (needUpdateData) {
      currentClientData.accessLevelCd =
        client?.accessLevelCd || currentClientData.accessLevelCd;
      currentClientData.userId = client?.user?.id || currentClientData.userId;
      currentClientData.accountName =
        client?.accountName || currentClientData.accountName;
      currentClientData.company = client?.company || currentClientData.company;
      currentClientData.industryTag =
        client?.industryTag?.id || currentClientData.industryTag;
      currentClientData.webAdress =
        client?.webAdress || currentClientData.webAdress;
    }

    if (needUpdateData) {
      currentClientData.clientContacts = [];
      currentClientData.clientCreditCards = [];
      currentClientData.clientShippingAddresses = [];
    }

    if (needUpdateData && client.clientContacts?.length) {
      client.clientContacts.forEach(
        (
          clientContact: {
            id: any;
            firstName: any;
            lastName: any;
            email: any;
            phone1: any;
            phone2: any;
            fax: any;
            jobTitle: any;
            birthday: any;
            sex: string | number | undefined;
            note: any;
          },
          index: number,
        ) => {
          const defaultClientContactId = client.defaultContact?.id;

          setDefaultClientContactId(defaultClientContactId || 0);

          if (defaultClientContactId) {
            setCurrentClientContactId(defaultClientContactId);
          }

          if (currentClientData.clientContacts) {
            currentClientData.clientContacts.push({
              id: clientContact.id ?? clearClientContact.id,
              firstName:
                clientContact.firstName ?? clearClientContact.firstName,
              lastName: clientContact.lastName ?? clearClientContact.lastName,
              email: clientContact.email ?? clearClientContact.email,
              phone1: clientContact.phone1 ?? clearClientContact.phone1,
              phone2: clientContact.phone2 ?? clearClientContact.phone2,
              fax: clientContact.fax ?? clearClientContact.fax,
              jobTitle: clientContact.jobTitle ?? clearClientContact.jobTitle,
              birthday: clientContact.birthday ?? clearClientContact.birthday,
              sex:
                clientContact.sex === "n/a"
                  ? 0
                  : typeof clientContact.sex === "number"
                    ? clientContact.sex
                    : 0,
              note: clientContact.note ?? clearClientContact.note,
            });
          }
        },
      );
    }
    if (needUpdateData && client.clientCreditCards?.length) {
      client.clientCreditCards.forEach(
        (
          card: {
            id: any;
            holderName: any;
            expirationDate: any;
            cardNumber: any;
            verificationNumber: any;
            creditCardType: string;
            createdAt: string;
          },
          index: number,
        ) => {
          if (currentClientData.clientCreditCards) {
            currentClientData.clientCreditCards.push({
              id: card.id ?? clearCreditCard.id,
              holderName: card.holderName ?? clearCreditCard.holderName,
              expirationYear:
                card.expirationDate?.split("-")?.[0] ??
                clearCreditCard.expirationMonth,
              expirationMonth:
                card.expirationDate?.split("-")?.[1] ??
                clearCreditCard.expirationYear,
              cardNumber: card.cardNumber ?? clearCreditCard.cardNumber,
              verificationNumber:
                card.verificationNumber ?? clearCreditCard.verificationNumber,
              cardType: card.creditCardType ?? "",
              createdAt: card.createdAt ?? "",
            });
          }
        },
      );
    }

    if (needUpdateData && client.shippingAddresses?.length) {
      const defaultShippingAddressId =
        client.defaultContact.shippingAddress?.id;
      const defaultBillingAddressId = client.defaultContact.billingAddress?.id;

      setDefaultShippingAddressId(defaultShippingAddressId || 0);
      setDefaultBillingAddressId(defaultBillingAddressId || 0);

      client.shippingAddresses.forEach(
        (
          shippingAddress: {
            id: any;
            typeAddress: any;
            address: any;
            address2: any;
            city: any;
            state: any;
            country: { id: number };
            zip: any;
            attentionTo: any;
          },
          index: number,
        ) => {
          if (currentClientData.clientShippingAddresses) {
            currentClientData.clientShippingAddresses.push({
              id: shippingAddress.id ?? clearShippingAddress.id,
              typeAddress:
                shippingAddress.typeAddress ?? clearShippingAddress.typeAddress,
              address: shippingAddress.address ?? clearShippingAddress.address,
              address2:
                shippingAddress.address2 ?? clearShippingAddress.address2,
              city: shippingAddress.city ?? clearShippingAddress.city,
              state: shippingAddress.state ?? clearShippingAddress.state,
              countryId:
                typeof shippingAddress.country?.id === "number"
                  ? shippingAddress.country?.id
                  : clearShippingAddress.countryId,
              zip: shippingAddress.zip ?? clearShippingAddress.zip,
              createBothAddress: clearShippingAddress.createBothAddress,
              attentionTo:
                shippingAddress.attentionTo ?? clearShippingAddress.attentionTo,
            });
          }
        },
      );
    }

    setClientEditData(currentClientData);
  }, [client, clientId, isLoadingClient]);

  const clearSearchParams = useCallback(() => {
    searchParams.delete("clientId");
    setSearchParams(searchParams);
  }, [searchParams]);

  const onGrantTemporaryAccess = useCallback(async () => {
    setIsLoadingGrantTemporaryAccess(true);

    await grantTemporaryAccess({
      variables: {
        clientId,
        userId: +userDetails?.user?.id,
      },
    })
      .then((res) => {
        const { data } = res;

        if (!data) return;

        const { grantTemporaryAccess } = data;

        if (grantTemporaryAccess === "Updated successfully.") {
          setIsNeedUpdateClient(true);
          setNotification([
            {
              type: "SUCCESS",
              message: `Grant Temporary Access updated successfully`,
            },
          ]);
        }
      })
      .finally(() => {
        setIsLoadingGrantTemporaryAccess(false);
      });
  }, [userDetails, clientId]);

  const getClient = useCallback(async () => {
    setIsLoadingClient(true);

    await fetchClient({ variables: { clientId } })
      .then((res) => {
        const { data } = res;

        if (!data) return;

        const { client } = data;

        if (!client) {
          return;
        }

        setClient(client);
      })
      .finally(() => {
        setIsLoadingClient(false);
        if (isNeedUpdateClient) {
          setIsNeedUpdateClient(false);
        }
      });
  }, [isNeedUpdateClient, clientId]);

  const onUpdateClient = useCallback(async () => {
    setIsLoadingUpdateClient(true);
    const filteredClientContacts = () => {
      const tmpClientContacts = clientEditData.clientContacts;

      tmpClientContacts?.forEach((tmpClientContactItem, index) => {
        if (
          tmpClientContacts &&
          (!tmpClientContactItem.firstName || !tmpClientContactItem.email)
        ) {
          tmpClientContacts.splice(index, 1);
          return;
        }
      });

      return tmpClientContacts && tmpClientContacts.length
        ? tmpClientContacts
        : undefined;
    };

    const filteredCreditCards = () => {
      const tmpCreditCards = clientEditData.clientCreditCards;
      tmpCreditCards?.forEach((tmpCreditCardItem, index) => {
        if (
          (tmpCreditCards &&
            (!tmpCreditCardItem.holderName ||
              (!tmpCreditCardItem.cardNumber && tmpCreditCardItem.id) ||
              !tmpCreditCardItem.expirationMonth ||
              !tmpCreditCardItem.expirationYear)) ||
          !tmpCreditCardItem.verificationNumber
        ) {
          tmpCreditCards.splice(index, 1);
        }
      });

      return tmpCreditCards && tmpCreditCards.length
        ? tmpCreditCards?.map((tmpCreditCardItem) => ({
            id: tmpCreditCardItem.id,
            holderName: tmpCreditCardItem.holderName,
            expirationDate:
              tmpCreditCardItem.expirationMonth &&
              tmpCreditCardItem.expirationYear
                ? `${tmpCreditCardItem.expirationYear}-${tmpCreditCardItem.expirationMonth}`
                : "",
            cardNumber: tmpCreditCardItem.id
              ? undefined
              : tmpCreditCardItem.cardNumber,
            verificationNumber: tmpCreditCardItem.verificationNumber
              ? parseInt(tmpCreditCardItem.verificationNumber)
              : "",
          }))
        : undefined;
    };

    const filteredShippingAddresses = () => {
      const tmpShippingAddresses = clientEditData.clientShippingAddresses;
      tmpShippingAddresses?.forEach((tmpShippingAddressItem, index) => {
        if (tmpShippingAddresses && !tmpShippingAddressItem.address) {
          tmpShippingAddresses.splice(index, 1);
        }
      });

      return tmpShippingAddresses && tmpShippingAddresses.length
        ? tmpShippingAddresses
        : undefined;
    };
    await updateClientModule({
      variables: {
        client: {
          id: clientId || undefined,
          accessLevelCd: clientEditData.accessLevelCd,
          userId: clientEditData.userId,
          accountName: clientEditData.accountName,
          company: clientEditData.company,
          webAdress: clientEditData.webAdress,
        },
        clientContacts: filteredClientContacts(),
        clientShippingAddresses: filteredShippingAddresses(),
        clientCreditCards: filteredCreditCards(),
      },
    })
      .then((res) => {
        const { data } = res;
        if (!data) return;
        const { updateClientModule } = data;

        if (!updateClientModule) return;

        const { id } = updateClientModule;

        if (id && clientId) {
          setIsNeedUpdateClient(true);
        }

        if (id && !clientId) {
          navigateTo(`/clients/${id}/client-info`);
        }
        if (id) {
          setNotification([
            { type: "SUCCESS", message: `Client #${id} successfully saved` },
          ]);
        }
      })
      .finally(() => {
        setIsLoadingUpdateClient(false);
      });
  }, [clientId, clientEditData, userDetails]);

  useEffect(() => {
    setShowDeleteModal(!!deleteClientId);
  }, [deleteClientId]);

  useEffect(() => {
    if (!showDeleteModal && deleteClientId) {
      setDeleteClientd(0);
    }
  }, [showDeleteModal]);

  const onDeleteClient = useCallback(async () => {
    setIsLoadingDeleteClient(true);

    await deleteClient({
      variables: {
        ids: [deleteClientId],
      },
    }).finally(() => {
      setIsLoadingDeleteClient(false);
      setDeleteClientd(0);
    });
  }, [deleteClientId]);

  const userNameInHeader = useMemo(() => {
    let currentUserNameInHeader = "";
    const currentClientContact = clientEditData?.clientContacts?.find(
      (el) => el.id === currentClientContactId,
    );
    const companyName = clientEditData?.company;

    if (currentClientContact) {
      const { firstName, lastName } = currentClientContact;

      currentUserNameInHeader = currentUserNameInHeader.concat(
        `${firstName || ""}${firstName && lastName ? " " : ""}${
          lastName || ""
        }`,
      );
    }

    if (companyName) {
      currentUserNameInHeader = currentUserNameInHeader.concat(
        `${currentUserNameInHeader.length ? " " : ""}${companyName}`,
      );
    }

    return currentUserNameInHeader;
  }, [currentClientContactId, clientEditData]);

  return (
    <section className="layout-content">
      <HeaderWithReactiveValueAndSaveBtn
        className="layout-content__header"
        headerItemList={[
          {
            label: "Account Name",
            value: clientEditData?.accountName || "",
            isVisible: true,
            name: "accountName",
          },
          {
            label: "Name",
            value: userNameInHeader,
            isVisible: true,
            name: "name",
          },
        ]}
        additionalButtons={[
            {
                label: "Delete",
                name: isLoadingDeleteClient
                    ? "inline-block animate-spin icon-spin4"
                    : "delete",
                color: "warning",
                onClick: () => {
                    setDeleteClientd(clientId || 0);
                },
                hidden: !clientId || !buttonsAccess?.includes("delete"),
                disabled: isLoadingDeleteClient,
            },
          {
            label: "Save!!!",
            name: "save",
            color: "success",
            icon: isLoadingUpdateClient
              ? "inline-block animate-spin icon-spin4"
              : "icon-floppy",
            onClick: () => {
              onUpdateClient();
            },
            disabled:
              isLoadingUpdateClient ||
              !isValidNonEmpty(clientEditData.accountName) ||
              !clientEditData.clientContacts?.some(
                (clientContact) =>
                  isValidNonEmpty(clientContact.firstName) &&
                  isValidEmail(clientContact.email),
              ),
            hidden: !buttonsAccess?.includes("save"),
          },
          {
            label: "Grant temporary access",
            name: isLoadingGrantTemporaryAccess
              ? "inline-block animate-spin icon-spin4"
              : "",
            color: "yellow",
            onClick: () => {
              onGrantTemporaryAccess();
            },
            hidden:
              !clientId ||
              !buttonsAccess?.includes("grantTemporaryAccess") ||
              client?.accessLevelCd !== 1,
            disabled: isLoadingGrantTemporaryAccess,
          },
        ]}
      />
      {isLoadingClient && <LoadingDesign />}
      {!isLoadingClient && (
        <TabsNew
          tabs={tabsList}
          currentTab={currentTab}
          defaultPath={`/clients/${clientId ? clientId : "new_client"}`}
        >
          {{
            ["client-info"]: (
              <EditClientClientInfoNew
                clientEditData={clientEditData}
                setNewClientData={(value) => {
                  setClientEditData(value);
                }}
                currentClientContactId={currentClientContactId}
                clearClientContactData={clearClientContact}
                tabsAccess={tabsAccess}
                defaultClientContactId={defaultClientContactId}
                setDefaultClientContactId={setDefaultClientContactId}
                setCurrentClientContactId={setCurrentClientContactId}
                setIsNeedUpdateClient={setIsNeedUpdateClient}
                clientId={clientId}
              />
            ),
            [`${clientId ? "edit-client" : "new-client"}-address`]: (
              <EditClientAddressNew
                clientEditData={clientEditData}
                setNewClientData={setClientEditData}
                clearShippingAddressesData={clearShippingAddress}
                currentShippingAddressesId={currentShippingAddressesId}
                tabsAccess={tabsAccess}
                defaultShippingAddressId={defaultShippingAddressId}
                defaultBillingAddressId={defaultBillingAddressId}
                setCurrentShippingAddressesId={setCurrentShippingAddressesId}
                setDefaultBillingAddressId={setDefaultBillingAddressId}
                setDefaultShippingAddressId={setDefaultShippingAddressId}
                setIsNeedUpdateClient={setIsNeedUpdateClient}
                clientId={clientId}
              />
            ),
            ["list-of-cards"]: (
              <EditClientListOfCardsNew
                clientEditData={clientEditData}
                setNewClientData={setClientEditData}
                currentCreditCardId={currentCreditCardId}
                clearCreditCardData={clearCreditCard}
                tabsAccess={tabsAccess}
                setIsNeedUpdateClient={setIsNeedUpdateClient}
                setCurrentCreditCardId={setCurrentCreditCardId}
                clientId={clientId}
              />
            ),
            ["history"]: (
              <EditClientHistoryNew clientId={client ? clientId : undefined} />
            ),
            ["logs"]: (
              <EditClientLogsNew clientId={client ? clientId : undefined} />
            ),
          }}
        </TabsNew>
      )}
      {showDeleteModal && (
        <ClientDeleteModal
          setShowDeleteModal={setShowDeleteModal}
          deleteTitle={"Delete client"}
          loading={isLoadingDeleteClient}
          onDelete={() => {
            onDeleteClient();
          }}
        />
      )}
    </section>
  );
};
export default EditClientNew;
