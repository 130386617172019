import { gql } from "@apollo/client";

export const CREATE_OLD_USER_NEW_USER = gql`
  mutation createOldUserNewUser($oldUserId: Int, $userId: Int) {
    createOldUserNewUser(userId: $userId, oldUserId: $oldUserId) {
      id
      oldUser
      createdAt
      updatedAt
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
export const UPDATE_OLD_USER_NEW_USER = gql`
  mutation updateOldUserNewUser($id: Int!, $oldUserId: Int, $userId: Int!) {
    updateOldUserNewUser(id: $id, userId: $userId, oldUserId: $oldUserId) {
      id
      oldUser
      updatedAt
      createdAt
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_OLD_USER_NEW_USER = gql`
  mutation removeOldUserNewUser($ids: [Int!]) {
    removeOldUserNewUser(ids: $ids)
  }
`;
export const CREATE_USER_EMAIL_DATA = gql`
  mutation createUserEmailData(
    $userId: Int!
    $domain: String!
    $userName: String!
    $password: String!
    $address: String!
    $port: String!
    $authentication: String!
    $sender: String!
    $lastUid: Int
  ) {
    createUserEmailData(
      userId: $userId
      domain: $domain
      userName: $userName
      password: $password
      address: $address
      port: $port
      authentication: $authentication
      sender: $sender
      lastUid: $lastUid
    ) {
      id
      domain
      userName
      password
      address
      port
      authentication
      sender
      lastUid
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
export const UPDATE_USER_EMAIL_DATA = gql`
  mutation updateUserEmailData(
    $id: Int!
    $userId: Int
    $domain: String
    $userName: String
    $password: String
    $address: String
    $port: String
    $authentication: String
    $sender: String
    $lastUid: Int
  ) {
    updateUserEmailData(
      id: $id
      userId: $userId
      domain: $domain
      userName: $userName
      password: $password
      address: $address
      port: $port
      authentication: $authentication
      sender: $sender
      lastUid: $lastUid
    ) {
      id
      domain
      userName
      password
      address
      port
      authentication
      sender
      lastUid
      user {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_USER_EMAIL_DATA = gql`
  mutation deleteUserEmailData($ids: [Int!]) {
    deleteUserEmailData(ids: $ids)
  }
`;
export const CREATE_ROLE = gql`
  mutation createRole($name: String, $user: [Int]) {
    createRole(name: $name, userIds: $user) {
      id
      name
      createdAt
      updatedAt
      users {
        id
        firstName
        lastName
      }
    }
  }
`;
export const UPDATE_ROLE = gql`
  mutation updateRole($name: String, $user: [Int], $id: Int!) {
    updateRole(name: $name, userIds: $user, id: $id) {
      id
      name
      createdAt
      updatedAt
      users {
        id
        firstName
        lastName
      }
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole($ids: [Int!]) {
    deleteRole(ids: $ids)
  }
`;

export const CREATE_USER = gql`
  mutation createUser(
    $email: String!
    $password: String!
    $userName: String!
    $firstName: String
    $lastName: String
    $phone: String
    $emailSignature: String
  ) {
    createUser(
      email: $email
      password: $password
      userName: $userName
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      emailSignature: $emailSignature
    ) {
      id
      email
      rememberCreatedAt
      signInCount
      currentSignInAt
      lastSignInAt
      currentSignInIp
      lastSignInIp
      createdAt
      updatedAt
      login
      userName
      firstName
      lastName
      phone
      emailSignature
      isDeleted
      roles {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser(
    $id: Int!
    $email: String
    $userName: String
    $firstName: String
    $lastName: String
    $phone: String
    $emailSignature: String
    $isDeleted: Boolean
    $password: String
  ) {
    updateUser(
      id: $id
      email: $email
      userName: $userName
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      emailSignature: $emailSignature
      isDeleted: $isDeleted
      password: $password
    ) {
      id
      email
      rememberCreatedAt
      signInCount
      currentSignInAt
      lastSignInAt
      currentSignInIp
      lastSignInIp
      createdAt
      updatedAt
      login
      userName
      firstName
      lastName
      phone
      emailSignature
      isDeleted
      roles {
        id
        name
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($ids: [Int!]) {
    deleteUser(ids: $ids)
  }
`;
export const CREATE_COUNTRY = gql`
  mutation createCountry($name: String!) {
    createCountry(name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_COUNTRY = gql`
  mutation updateCountry($id: Int!, $name: String) {
    updateCountry(id: $id, name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_COUNTRY = gql`
  mutation deleteCountry($ids: [Int!]) {
    deleteCountry(ids: $ids)
  }
`;
export const CREATE_STATE = gql`
  mutation createState($name: String, $alias: String, $tax: Float) {
    createState(name: $name, alias: $alias, tax: $tax) {
      id
      name
      createdAt
      alias
      updatedAt
      tax
    }
  }
`;
export const UPDATE_STATE = gql`
  mutation updateState($id: Int!, $name: String, $alias: String, $tax: Float) {
    updateState(id: $id, name: $name, alias: $alias, tax: $tax) {
      id
      name
      createdAt
      alias
      updatedAt
      tax
    }
  }
`;
export const DELETE_STATE = gql`
  mutation deleteState($ids: [Int!]) {
    deleteState(ids: $ids)
  }
`;
export const CREATE_TIMEZONE = gql`
  mutation createTimeZone($code: Int, $zone: Int) {
    createTimeZone(code: $code, zone: $zone) {
      id
      code
      zone
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_TIMEZONE = gql`
  mutation updateTimeZone($id: Int!, $code: Int!, $zone: Int!) {
    updateTimeZone(id: $id, code: $code, zone: $zone) {
      id
      code
      zone
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_TIMEZONE = gql`
  mutation deleteTimeZone($ids: [Int!]) {
    deleteTimeZone(ids: $ids)
  }
`;
export const CREATE_CRMSETTING = gql`
  mutation createCrmSetting($name: String, $value: String, $valueText: String) {
    createCrmSetting(name: $name, value: $value, valueText: $valueText) {
      id
      name
      value
      valueText
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_CRMSETTING = gql`
  mutation updateCrmSetting(
    $id: Int!
    $name: String
    $value: String
    $valueText: String
  ) {
    updateCrmSetting(
      id: $id
      name: $name
      value: $value
      valueText: $valueText
    ) {
      id
      name
      value
      valueText
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_CRMSETTING = gql`
  mutation deleteCrmSetting($ids: [Int!]) {
    deleteCrmSetting(ids: $ids)
  }
`;
export const CREATE_DOMAIN_IGNORE_LIST = gql`
  mutation createDomainIgnoreList($name: String) {
    createDomainIgnoreList(name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_DOMAIN_IGNORE_LIST = gql`
  mutation updateDomainIgnoreList($id: Int!, $name: String!) {
    updateDomainIgnoreList(id: $id, name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_DOMAIN_IGNORE_LIST = gql`
  mutation deleteDomainIgnoreList($ids: [Int!]) {
    deleteDomainIgnoreList(ids: $ids)
  }
`;
export const CREATE_IGNORE_WORD_SEARCH = gql`
  mutation createIgnoreWordSearch($word: String) {
    createIgnoreWordSearch(word: $word) {
      id
      word
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_IGNORE_WORD_SEARCH = gql`
  mutation updateIgnoreWordSearch($id: Int!, $word: String) {
    updateIgnoreWordSearch(id: $id, word: $word) {
      id
      word
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_IGNORE_WORD_SEARCH = gql`
  mutation deleteIgnoreWordSearch($ids: [Int!]) {
    deleteIgnoreWordSearch(ids: $ids)
  }
`;
export const CREATE_SYSTEM_ERROR = gql`
  mutation createSystemError($name: String, $text: String, $data: String) {
    createSystemError(name: $name, text: $text, data: $data) {
      id
      name
      text
      data
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_SYSTEM_ERROR = gql`
  mutation updateSystemError(
    $id: Int!
    $name: String
    $text: String
    $data: String
  ) {
    updateSystemError(id: $id, name: $name, text: $text, data: $data) {
      id
      name
      text
      data
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_SYSTEM_ERROR = gql`
  mutation deleteSystemError($ids: [Int!]) {
    deleteSystemError(ids: $ids)
  }
`;
export const CREATE_USER_SETTING = gql`
  mutation createUserSetting($name: String, $value: String, $userId: Int) {
    createUserSetting(name: $name, value: $value, userId: $userId) {
      id
      name
      value
      user {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_USER_SETTING = gql`
  mutation updateUserSetting(
    $id: Int!
    $name: String
    $value: String
    $userId: Int
  ) {
    updateUserSetting(id: $id, name: $name, value: $value, userId: $userId) {
      id
      name
      value
      user {
        id
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_USER_SETTING = gql`
  mutation deleteUserSetting($ids: [Int!]) {
    deleteUserSetting(ids: $ids)
  }
`;
export const CREATE_PRODUCT = gql`
  mutation createProduct(
    $name: String
    $price: Float
    $description: String
    $priceMethod: String
    $priceOffset: Float
    $link: String
    $coverImageFile: String
    $coverImageFileName: String
  ) {
    createProduct(
      name: $name
      priceOffset: $priceOffset
      price: $price
      priceMethod: $priceMethod
      link: $link
      description: $description
      coverImageFile: $coverImageFile
      coverImageFileName: $coverImageFileName
    ) {
      id
      name
      price
      description
      createdAt
      priceMethod
      priceOffset
      coverImageUid
      coverImageName
      coverImageFile
      link
    }
  }
`;
export const UPDATE_PRODUCT = gql`
  mutation updateProduct(
    $id: Int!
    $name: String
    $price: Float
    $description: String
    $priceMethod: String
    $priceOffset: Float
    $link: String
    $coverImageFileName: String
    $coverImageFile: String
    $productCategoryId: Int
    $productOptionsIds: [Int]
    $deleteCoverImage: Boolean
  ) {
    updateProduct(
      id: $id
      name: $name
      priceOffset: $priceOffset
      price: $price
      priceMethod: $priceMethod
      link: $link
      description: $description
      coverImageFile: $coverImageFile
      coverImageFileName: $coverImageFileName
      productCategoryId: $productCategoryId
      productOptionsIds: $productOptionsIds
      deleteCoverImage: $deleteCoverImage
    ) {
      id
      name
      price
      description
      priceMethod
      priceOffset
      productOptionsProducts {
        id
        productOption {
          id
          title
        }
        createdAt
      }
      coverImageName
      coverImageUid
      coverImageFile
      link
    }
  }
`;
export const DELETE_PRODUCT = gql`
  mutation deleteProduct($ids: [Int!]) {
    deleteProduct(ids: $ids)
  }
`;
export const CREATE_HIGHLIGHT = gql`
  mutation createHighlight(
    $name: String
    $color: String
    $highlightType: Int
    $priority: Int
  ) {
    createHighlight(
      name: $name
      color: $color
      highlightType: $highlightType
      priority: $priority
    ) {
      id
      name
      color
      createdAt
      updatedAt
      highlightType
      priority
    }
  }
`;
export const UPDATE_HIGHLIGHT = gql`
  mutation updateHighlight(
    $name: String
    $color: String
    $highlightType: Int
    $priority: Int
    $id: Int!
  ) {
    updateHighlight(
      name: $name
      color: $color
      highlightType: $highlightType
      priority: $priority
      id: $id
    ) {
      id
      name
      color
      highlightType
      priority
    }
  }
`;
export const DELETE_HIGHLIGHT = gql`
  mutation deleteHighlight($ids: [Int!]) {
    deleteHighlight(ids: $ids)
  }
`;
export const CREATE_EXPENSE = gql`
  mutation createExpense($name: String) {
    createExpense(name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_EXPENSE = gql`
  mutation updateExpense($id: Int!, $name: String) {
    updateExpense(id: $id, name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_EXPENSE = gql`
  mutation deleteExpense($ids: [Int!]) {
    deleteExpense(ids: $ids)
  }
`;
export const CREATE_INDUSTRY_TAG = gql`
  mutation createIndustryTag($name: String) {
    createIndustryTag(name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_INDUSTRY_TAG = gql`
  mutation updateIndustryTag($id: Int!, $name: String) {
    updateIndustryTag(id: $id, name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_INDUSTRY_TAG = gql`
  mutation deleteIndustryTag($ids: [Int!]) {
    deleteIndustryTag(ids: $ids)
  }
`;
export const CREATE_QUICK_QUOTE_ADDITIONAL_INFO = gql`
  mutation createQuickQuoteAdditionalInfo(
    $description: String
    $price: Float
    $count: Int
    $default: Boolean
  ) {
    createQuickQuoteAdditionalInfo(
      description: $description
      price: $price
      count: $count
      isDefault: $default
    ) {
      id
      description
      price
      count
      isDefault
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_QUICK_QUOTE_ADDITIONAL_INFO = gql`
  mutation updateQuickQuoteAdditionalInfo(
    $id: Int!
    $description: String
    $price: Float
    $count: Int
    $default: Boolean
  ) {
    updateQuickQuoteAdditionalInfo(
      id: $id
      description: $description
      price: $price
      count: $count
      isDefault: $default
    ) {
      id
      description
      price
      count
      isDefault
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_QUICK_QUOTE_ADDITIONAL_INFO = gql`
  mutation deleteQuickQuoteAdditionalInfo($ids: [Int!]) {
    deleteQuickQuoteAdditionalInfo(ids: $ids)
  }
`;
export const CREATE_QUICK_QUOTE_LINE = gql`
  mutation createQuickQuoteLine(
    $name: String
    $price: Float
    $description: String
    $count: Int
  ) {
    createQuickQuoteLine(
      name: $name
      price: $price
      description: $description
      count: $count
    ) {
      id
      name
      description
      price
      count
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_QUICK_QUOTE_LINE = gql`
  mutation updateQuickQuoteLine(
    $id: Int!
    $name: String
    $price: Float
    $description: String
    $count: Int
  ) {
    updateQuickQuoteLine(
      id: $id
      name: $name
      price: $price
      description: $description
      count: $count
    ) {
      id
      name
      description
      price
      count
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_QUICK_QUOTE_LINE = gql`
  mutation deleteQuickQuoteLine($ids: [Int!]) {
    deleteQuickQuoteLine(ids: $ids)
  }
`;
export const USER_MASS_DELETE = gql`
  mutation userMassDelete($ids: [Int]!, $isDeleted: Boolean!) {
    userMassDelete(ids: $ids, isDeleted: $isDeleted)
  }
`;
