import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useReactiveVar } from "@apollo/client";
import { setNotification, userContextData } from "../../../../helpers/cache";
import { Params } from "../../../../types/Main";
import { QUOTE_ITEM_QUERY } from "../../Services/Queries/QuoteItemQuery";
import { onChangeParamsCallback } from "../../../../helpers/Main";
import {
  ClientCreditCard,
  EditClientDataClientContact,
  EditClientDataCreditCard,
  EditClientDataShippingAddress,
} from "../../../../types/Client";
import {
  CREATE_ORDER,
  CREATE_QUOTE,
  DELETE_QUOTE,
  UPDATE_ORDER,
  UPDATE_QUOTE,
} from "../../Services/Mutations/Mutations";
import { QuoteEditData } from "../../../../types/Quote";
import { TabListNew } from "../../../../types/Tabs";
import { LoadingDesign } from "../../../../helpers";
import TabsNew from "../../../../components/Form/El/Tabs";
import HeaderWithReactiveValueAndSaveBtn from "../../../../components/Header/HeaderWithReactiveValueAndSaveBtn";
import EditQuoteQuoteInfo from "./EditQuoteQuoteInfo";
import EditQuoteLogs from "./EditQuoteLogs";
import EditQuoteTransactionLogs from "./EditQuoteTransactionLogs";
import EditQuoteArtWork from "./EditQuoteArtWork";
import { cloneDeep } from "lodash";
import { CLIENT_ITEM_QUERY } from "../../Services/Queries/ClientItemQuery";
import {
  FormSelectGroupOption,
  FormSelectOptionItem,
} from "../../../../types/Form";
import EditQuoteShippingInfo from "./EditQuoteShippingInfo";
import EditQuoteMisc from "./EditQuoteMisc";
import { ProductEditData, ProductFromServer } from "../../../../types/Product";
import QuoteDeleteModal from "./QuoteDeleteModal1";
import {editQuoteSetObjForSending, getAddressInfo} from "./EditQuoteSetObjForSending";
import { isValidEmail, isValidNonEmpty } from "../../../../helpers/Validation";
import EditQuotePayment from "./EditQuotePayment/EditQuotePayment";

const EditQuote: FC<{
  refetchSalesCommission: Function;
  quoteId?: number;
}> = ({ refetchSalesCommission, quoteId }) => {
  const navigateTo = useNavigate();

  const userDetails: any = useReactiveVar(userContextData);

  const userId = useMemo(() => userDetails?.user?.id, [userDetails]);
  const [clientId, setClientId] = useState<number | undefined>();

  const [prevParams, setPrevParams] = useState<Params>({});
  const [paymentTabUpdated, setPaymentTabUpdated] = useState(false);
  const [productUpdated, setProductUpdated] = useState(false);

  const [isLoadingQuote, setIsLoadingQuote] = useState(false);
  const [isLoadingClient, setIsLoadingClientInQuote] = useState(false);

  const [isNeedUpdateQuote, setIsNeedUpdateQuote] = useState(false);
  const [creditCardId, setCreditCardId] = useState<number | null>(null);
  const [defaultClientContactId, setDefaultClientContactId] = useState<number | null>(null);
  const [defaultShippingAddressId, setDefaultShippingAddressId] = useState<number | null>(null);
  const [defaultBillingAddressId, setDefaultBillingAddressId] = useState<number | null>(null);

  const [clientContactsList, setClientContactsList] = useState<
    FormSelectGroupOption[]
  >([{ options: [{ id: 0, value: "new" }] }]);
  const [shippingAddressList, setShippingAddressList] = useState<
    FormSelectGroupOption[]
  >([{ options: [{ id: 0, value: "new" }] }]);
  const [billingAddressList, setBillingAddressList] = useState<
    FormSelectGroupOption[]
  >([{ options: [{ id: 0, value: "new" }] }]);

  useEffect(() => {
    if (quoteId) {
      setIsNeedUpdateQuote(true);
    }

    if (!quoteId && quote) {
      setQuote(undefined);
    }
  }, [quoteId]);
  const location = useLocation();

  const pathname = useMemo(() => location.pathname, [location]);
  const state = useMemo(() => {
    return location.state as { clientId?: number };
  }, [location]);

  const currentTab = useMemo(() => pathname.split("/")[3], [pathname]);

  const [quote, setQuote] = useState<any>();
  const [clientInQuote, setClientInQuote] = useState<any>();

  const [isLoadingUpdateQuote, setIsLoadingUpdateQuote] = useState(false);

  useEffect(() => {
    if (state?.clientId) {
      setClientId(state?.clientId);
    }
  }, [state?.clientId]);

  const isLoadingData = useMemo(
    () => {
      return isLoadingClient ||
        isLoadingQuote ||
        (clientId && !clientInQuote) ||
        (quoteId && !quote)
    },
    [isLoadingClient, isLoadingQuote, clientId, clientInQuote, quoteId, quote],
  );

  const clearClientContact = useMemo(
    (): EditClientDataClientContact => ({
      id: undefined,
      firstName: "",
      lastName: "",
      email: "",
      phone1: "",
      phone2: "",
      fax: "",
      jobTitle: "",
      birthday: null,
      sex: 0,
      note: "",
    }),
    [],
  );

  const clearCreditCard = useMemo(
    (): EditClientDataCreditCard => ({
      id: undefined,
      holderName: "",
      expirationYear: "",
      expirationMonth: "",
      cardNumber: "",
      verificationNumber: "",
    }),
    [],
  );

  const clearAddress = useMemo(
    (): EditClientDataShippingAddress => ({
      id: undefined,
      typeAddress: "Shipping",
      address: "",
      address2: "",
      city: "",
      state: "",
      countryId: 1,
      zip: "",
      attentionTo: "",
    }),
    [],
  );

  const [stateTax, setStateTax] = useState<number | null>(null);

  const clearQuoteEditData = useMemo((): QuoteEditData => {
    return {
      client: {
        id: undefined,
        accessLevelCd: 0,
        userId: userId || 0,
        accountName: "",
        company: "",
        industryTag: undefined,
        webAddress: "",
        clientContacts: cloneDeep(clearClientContact),
        clientShippingAddresses: cloneDeep(clearAddress),
        clientBillingAddresses: {
          ...clearAddress,
          typeAddress: "Billing",
        },
        clientCreditCards: cloneDeep(clearCreditCard),
      },
      quoteAdditionalRows: [],
      leadSource: 0,
      leadSourceStatus: 0,
      order: {
        id: undefined,
        paymentType: undefined,
        paymentStatus: undefined,
        clientCreditCardId: 0,
        shippingMethod: undefined,
        watchTracking1: false,
        watchTracking2: false,
        customerPaysShipping: false,
        trackingNumber1: "",
        trackingNumber2: "",
        specialInstructions: "",
        misc: "",
        note: "",
        useUpsFedexAcct: "",
      },
      orderProducts: Array.from({ length: 10 }, (_, i) => ({
        description: null,
        priceTableId: null,
        productId: null,
        productOptionString: null,
        quantity: null,
        unitPrice: null,
        isNewProduct: true,
      })),
      requiredDeliveryDate: null,
      shippingTax: null,
      status: 0,
      supplierId: 1,
      useAdditionalLine: false,
      useInventory: false,
      userId: userId || 0,
      withoutTax: null,
      withoutTotal: null,
      note: "",
      quotePaymentLinkTpls: [],
    };
  }, [userId, clearClientContact, clearCreditCard, clearAddress]);

  const [quoteEditData, setQuoteEditData] = useState<QuoteEditData>(
    cloneDeep(clearQuoteEditData),
  );

  const accessControlStructure = useMemo(
    () => userDetails?.user?.accessControlStructure,
    [userDetails],
  );

  const accessModules = useMemo(
    () => accessControlStructure?.modules,
    [accessControlStructure],
  );

  const quoteAccess = useMemo(
    () => accessModules?.quote?.tabs?.[quoteId ? "editQuote" : "newQuote"],
    [accessModules, quoteId],
  );

  const buttonsAccess = useMemo(() => quoteAccess?.buttons, [quoteAccess]);

  const editClientAccess = useMemo(
    () => accessModules?.client?.tabs?.editClient,
    [accessModules],
  );

  const [createQuote] = useMutation(CREATE_QUOTE, {
    onError: (error) => {
      setNotification([
        {
          type: "ERROR",
          message: `${error?.message}`,
        },
      ]);
    },
    onCompleted: (data) => {
      if (data?.createQuote?.id) {
        navigateTo(`/quotes/${data?.createQuote?.id}`);
        setNotification([
          {
            type: "SUCCESS",
            message: `Quote #${data?.createQuote?.id} successfully saved`,
          },
        ]);
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const [updateOrderServerData] = useMutation(UPDATE_ORDER, {
    onError: (error) => {
      setNotification([
        {
          type: "ERROR",
          message: `${error?.message}`,
        },
      ]);
    },
    notifyOnNetworkStatusChange: true,
  });

  const [createOrderServerData] = useMutation(CREATE_ORDER, {
    onError: (error) => {
      setNotification([
        {
          type: "ERROR",
          message: `${error?.message}`,
        },
      ]);
    },
    notifyOnNetworkStatusChange: true,
  });

  const [updateQuotePartial] = useMutation(UPDATE_QUOTE, {
    onError: (error) => {
      setNotification([
        {
          type: "ERROR",
          message: `${error?.message}`,
        },
      ]);
    },
    onCompleted: (data) => {
      if (data?.updateQuote?.id) {
        setIsNeedUpdateQuote(true);
        setNotification([
          {
            type: "SUCCESS",
            message: `Quote #${data?.updateQuote?.id} successfully saved`,
          },
        ]);
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const onUpdateQuotePartial = useCallback(
    async (data: Partial<QuoteEditData>) => {
      if (data.order && data.order.id) {
        await updateOrderServerData({
          variables: {
            id: quoteEditData.id,
            ...data.order
          },
        })
      }

      await updateQuotePartial({
        variables: {
          id: quoteEditData.id,
          ...data,
        },
      });
    },
    [quoteEditData],
  );

  const tabsAccess = useMemo(() => quoteAccess?.tabs, [quoteAccess]);

  const tabQuoteInfoAccess = useMemo(() => tabsAccess?.quoteInfo, [tabsAccess]);
  const tabMiscAccess = useMemo(() => tabsAccess?.miscellaneous, [tabsAccess]);
  const tabShippingAndAdditionalInfoAccess = useMemo(
    () => tabsAccess?.shippingInfoAndAdditionalInfo,
    [tabsAccess, quote],
  );
  const tabArtWorkAccess = useMemo(
    () => tabsAccess?.["artWork&Design"],
    [tabsAccess],
  );
  const tabTransactionLogsAccess = useMemo(
    () => tabsAccess?.transactionLogs,
    [tabsAccess, quote],
  );
  const tabPaymentAccess = useMemo(
    () => tabsAccess?.payment,
    [tabsAccess, quote],
  );

  const isValidQuoteStatus = useMemo(() => !!quote?.status, [quote]);

  const tabLogsAccess = useMemo(() => tabsAccess?.logs, [tabsAccess]);

  const tabsList = useMemo(
    (): TabListNew => [
      {
        label: "Quote Info",
        iconName: "icon-info-circled text-[#69AA46]",
        name: "quote_info",
        hidden: !tabQuoteInfoAccess,
      },
      {
        label: "Miscellaneous",
        iconName: "icon-comment text-[#478FCA]",
        name: "miscellaneous",
        hidden: !tabMiscAccess || !quoteId,
      },
      {
        label: "Shipping Info & Additional Info",
        iconName: "icon-truck text-[#C6699F]",
        name: "shipping_info",
        hidden: !tabShippingAndAdditionalInfoAccess || !isValidQuoteStatus,
      },
      {
        label: "Payment",
        iconName: "icon-credit-card text-[#C6699F]",
        name: "payment_info",
        hidden: !tabPaymentAccess || !quoteId || !isValidQuoteStatus,
      },
      {
        label: "Artwork/Design",
        iconName: "icon-picture  text-[#DD5A43]",
        name: "artwork",
        hidden: !tabArtWorkAccess || !quoteId,
      },
      {
        label: "Transaction Logs",
        iconName: "icon-exchange  text-[#DD5A43]",
        name: "transaction_logs",
        hidden: !tabTransactionLogsAccess || !quoteId || !isValidQuoteStatus,
      },
      {
        label: "Logs",
        iconName: "icon-list text-[#69AA46]",
        name: "logs",
        hidden: !tabLogsAccess || !quoteId,
      },
    ],
    [
      tabQuoteInfoAccess,
      tabMiscAccess,
      tabShippingAndAdditionalInfoAccess,
      tabArtWorkAccess,
      tabTransactionLogsAccess,
      tabLogsAccess,
      quoteId,
      isValidQuoteStatus,
    ],
  );

  useEffect(() => {
    if (!quote && userId) {
      setQuoteEditData((prevState) => {
        return {
          ...prevState,
          userId: userId,
          client: {
            ...prevState.client,
            userId: userId
          }
        }
      })
    }
  }, [userId]);

  const onErrorFetch = useCallback(() => {
    navigateTo("/quotes/history");
  }, []);

  const [fetchQuote] = useLazyQuery(QUOTE_ITEM_QUERY, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      onErrorFetch();
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const [fetchClient] = useLazyQuery(CLIENT_ITEM_QUERY, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      onErrorFetch();
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const creditCardList = useMemo((): FormSelectOptionItem[] => {
    const cardList: FormSelectOptionItem[] = [
      {
        id: 0,
        value: "",
      },
    ];

    clientInQuote?.clientCreditCards.forEach((card: ClientCreditCard) => {
      cardList.push({
        id: card.id,
        value: `${card.cardNumber} ${card.holderName}`,
      });
    });

    return cardList;
  }, [clientInQuote]);

  const [deleteQuote] = useMutation(DELETE_QUOTE, {
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      setNotification([{ type: "ERROR", message: error?.message }]);
    },
  });

  const roleName = useMemo(
    () => userDetails?.user?.roles[0]?.name?.toLowerCase(),
    [userDetails],
  );

  const onSaveQuote = useCallback(
    async (data: QuoteEditData) => {
      setIsLoadingUpdateQuote(true);

      const dataObj = editQuoteSetObjForSending(data);

      const onSaveCallback = async () => {
        const isNeedSaveOrder = tabShippingAndAdditionalInfoAccess && isValidQuoteStatus

        if (quoteId && dataObj.order && dataObj.order.id && isNeedSaveOrder) {
          await updateOrderServerData({
            variables: {
              quoteId: quoteId,
              ...dataObj.order
            },
          })
        }

        if (quoteId && dataObj.order && !dataObj.order.id && isNeedSaveOrder) {
          await createOrderServerData({
            variables: {
              quoteId: quoteId,
              ...dataObj.order
            },
          })
        }

        const getDataByRole = () => {
          if (roleName === 'supplier') {
            return {
              status: dataObj.status,
              id: dataObj.id
            }
          }

          return { ...dataObj, shippingTax: stateTax || 0 }
        }

        if (quoteId) {
          await setProductUpdated(true);
          return await updateQuotePartial({
            variables: getDataByRole(),
          });
        }
        return await createQuote({
          variables: getDataByRole(),
        });
      };

      setIsNewFieldsClientQuote({
        clientContact: !quoteEditData.client.clientContacts.id,
        shippingAddress: !quoteEditData.client.clientShippingAddresses.id && !!getAddressInfo(quoteEditData.client.clientShippingAddresses, "Shipping"),
        billingAddress: !quoteEditData.client.clientBillingAddresses.id && !!getAddressInfo(quoteEditData.client.clientBillingAddresses, "Billing"),
      })

      await onSaveCallback()
        .then(() => {
          if (roleName === "sales") {
            refetchSalesCommission();
          }
        })
        .finally(() => {
          setIsLoadingUpdateQuote(false);
        });
    },
    [quoteEditData, quoteId, roleName, tabShippingAndAdditionalInfoAccess, stateTax],
  );

  const hasEditAndDeleteAccess = useMemo(() => {
    return (
      roleName !== "sales" ||
      (roleName === "sales" &&
        ((quote?.status === 0 ||
          quote?.status === 1 ||
          quote?.status === 10 ||
          quote?.status === 11) || !quote))
    );
  }, [roleName, quote]);

  useEffect(() => {
    const currentParams = { quoteId, isNeedUpdateQuote };
    onChangeParamsCallback(
      prevParams,
      currentParams,
      (newParams) => setPrevParams(newParams),
      () => {
        if ((typeof quoteId === "number" && isNeedUpdateQuote) && !isLoadingQuote) {
          getQuote();
        }
      },
    );
  }, [quoteId, prevParams, isNeedUpdateQuote]);

  const getClientInQuote = useCallback(async (id: number) => {
    if (!id) return;
    setIsLoadingClientInQuote(true);

    await fetchClient({ variables: { id } })
      .then((res) => {
        const { data } = res;

        if (!data) return;

        const { client } = data;
        if (!client) {
          return;
        }
        setClientInQuote(client);
      })
      .finally(() => {
        setIsLoadingClientInQuote(false);
      });
  }, []);

  const [isNeedUpdateClientData, setIsNeedUpdateClientData] = useState(false);
  const [isNewFieldsClientQuote, setIsNewFieldsClientQuote] = useState<{
    shippingAddress: boolean,
    clientContact: boolean,
    billingAddress: boolean
  }>({
    shippingAddress: false,
    clientContact: false,
    billingAddress: false
  });

  useEffect(() => {
    if (clientId) {
      getClientInQuote(clientId);
    }
  }, [clientId]);

  const setClientData = useCallback(() => {
    if (isLoadingData) return;

    setQuoteEditData(prevState => {
      const tmpEditData = cloneDeep(prevState);
      const tmpClearData = cloneDeep(clearQuoteEditData);

      tmpEditData.client.id = clientInQuote?.id ?? tmpClearData.client.id;

      const setNewListValue = (type: 'clientContact' | 'shippingAddress' | 'billingAddress', list: FormSelectGroupOption[]) => {
        const getLastElInClientSelectList = () => {
          const lastEl = list.slice(-1)?.[0]?.options?.slice(-1)?.[0];

          return lastEl && typeof lastEl === 'object' && typeof lastEl.id === 'number' ? lastEl.id : 0
        }

        if (isNewFieldsClientQuote[type] && type === 'clientContact' && !isNeedUpdateClientData) {
          tmpEditData.client.clientContacts.id = getLastElInClientSelectList()
        }

        if (isNewFieldsClientQuote[type] && type === 'shippingAddress' && !isNeedUpdateClientData) {
          tmpEditData.client.clientShippingAddresses.id = getLastElInClientSelectList()
        }

        if (isNewFieldsClientQuote[type] && type === 'billingAddress' && !isNeedUpdateClientData) {
          tmpEditData.client.clientBillingAddresses.id = getLastElInClientSelectList()
        }
      }

      if (clientInQuote?.clientContacts) {
        const clientsContactsList = clientInQuote.clientContacts.map(
          (el: EditClientDataClientContact) => {
            let userName = el.firstName;

            if (el.lastName) {
              userName = userName.concat(` ${el.lastName}`);
            }

            return {
              id: el.id,
              value: userName,
            };
          },
        );

        setDefaultClientContactId(clientInQuote.defaultContact.id);
        setDefaultBillingAddressId(clientInQuote.defaultContact.billingAddress?.id || null);
        setDefaultShippingAddressId(clientInQuote.defaultContact.shippingAddress?.id || null);

        const currentClientContactList: FormSelectGroupOption[] = [{ options: [{ id: 0, value: "new" }, ...clientsContactsList] }];

        setClientContactsList(currentClientContactList);

        setNewListValue('clientContact', currentClientContactList)
      }

      const billingAddresses =
        clientInQuote?.shippingAddresses?.filter(
          (el: EditClientDataShippingAddress) => el.typeAddress === "Billing",
        ) || [];
      const shippingAddresses =
        clientInQuote?.shippingAddresses?.filter(
          (el: EditClientDataShippingAddress) => el.typeAddress === "Shipping",
        ) || [];

      if (shippingAddresses) {
        const shippingAddressesList = shippingAddresses.map(
          (el: EditClientDataShippingAddress) => {
            return {
              id: el.id,
              value: el.address,
            };
          },
        );

        const currentShippingAddressList: FormSelectGroupOption[] = [
          { options: [{ id: 0, value: "new" }, ...shippingAddressesList] },
        ];

        setShippingAddressList(currentShippingAddressList);

        setNewListValue('shippingAddress', currentShippingAddressList)
      }

      if (billingAddresses) {
        const billingAddressesList = billingAddresses.map(
          (el: EditClientDataShippingAddress) => {
            return {
              id: el.id,
              value: el.address,
            };
          },
        );

        const currentBillingAddressList: FormSelectGroupOption[] = [
          { options: [{ id: 0, value: "new" }, ...billingAddressesList] },
        ];

        setBillingAddressList(currentBillingAddressList);

        setNewListValue('billingAddress', currentBillingAddressList)
      }

      return tmpEditData
    })


    setIsNewFieldsClientQuote({
      clientContact: false,
      shippingAddress: false,
      billingAddress: false,
    })

    if (!isNeedUpdateClientData) {
      return;
    }

    setQuoteEditData(prevState => {
      const tmpEditData = cloneDeep(prevState);
      const tmpClearData = cloneDeep(clearQuoteEditData);

      tmpEditData.client.accessLevelCd =
        clientInQuote?.accessLevelCd ?? tmpClearData.client.accessLevelCd;
      tmpEditData.client.userId =
        clientInQuote?.user?.id ?? tmpClearData.client.userId;
      tmpEditData.client.company =
        clientInQuote?.company ?? tmpClearData.client.company;
      tmpEditData.client.industryTag =
        clientInQuote?.industryTag?.id ?? tmpClearData.client.industryTag;
      tmpEditData.client.webAddress =
        clientInQuote?.webAdress ?? tmpClearData.client.webAddress;
      tmpEditData.client.accountName =
        clientInQuote?.accountName ?? tmpClearData.client.accountName;

      if (!!tmpEditData.client.clientContacts.id) {
        const clientContactFromClientInQuote =
          clientInQuote?.clientContacts?.find(
            (el: any) => el.id === tmpEditData.client.clientContacts.id,
          );

        tmpEditData.client.clientContacts.id =
          clientContactFromClientInQuote?.id ??
          tmpClearData.client.clientContacts.id;
        tmpEditData.client.clientContacts.sex =
          clientContactFromClientInQuote?.sex === "n/a"
            ? tmpClearData.client.clientContacts.sex
            : typeof clientContactFromClientInQuote?.sex === "number"
              ? clientContactFromClientInQuote?.sex
              : tmpClearData.client.clientContacts.sex;
        tmpEditData.client.clientContacts.fax =
          clientContactFromClientInQuote?.fax ??
          tmpClearData.client.clientContacts.fax;
        tmpEditData.client.clientContacts.email =
          clientContactFromClientInQuote?.email ??
          tmpClearData.client.clientContacts.email;
        tmpEditData.client.clientContacts.note =
          clientContactFromClientInQuote?.note ??
          tmpClearData.client.clientContacts.note;
        tmpEditData.client.clientContacts.jobTitle =
          clientContactFromClientInQuote?.jobTitle ??
          tmpClearData.client.clientContacts.jobTitle;
        tmpEditData.client.clientContacts.phone2 =
          clientContactFromClientInQuote?.phone2 ??
          tmpClearData.client.clientContacts.phone2;
        tmpEditData.client.clientContacts.phone1 =
          clientContactFromClientInQuote?.phone1 ??
          tmpClearData.client.clientContacts.phone1;
        tmpEditData.client.clientContacts.lastName =
          clientContactFromClientInQuote?.lastName ??
          tmpClearData.client.clientContacts.lastName;
        tmpEditData.client.clientContacts.firstName =
          clientContactFromClientInQuote?.firstName ??
          tmpClearData.client.clientContacts.firstName;
        tmpEditData.client.clientContacts.birthday =
          clientContactFromClientInQuote?.birthday ||
          tmpClearData.client.clientContacts.birthday;
      }

      if (!!tmpEditData.client.clientShippingAddresses.id) {
        const shippingAddressFromClientInQuote =
          clientInQuote?.shippingAddresses?.find(
            (el: any) => el.id === tmpEditData.client.clientShippingAddresses.id,
          );

        tmpEditData.client.clientShippingAddresses.id =
          shippingAddressFromClientInQuote?.id ??
          tmpClearData.client.clientShippingAddresses.id;
        tmpEditData.client.clientShippingAddresses.address =
          shippingAddressFromClientInQuote?.address ??
          tmpClearData.client.clientShippingAddresses.address;
        tmpEditData.client.clientShippingAddresses.address2 =
          shippingAddressFromClientInQuote?.address2 ??
          tmpClearData.client.clientShippingAddresses.address2;
        tmpEditData.client.clientShippingAddresses.city =
          shippingAddressFromClientInQuote?.city ??
          tmpClearData.client.clientShippingAddresses.city;
        tmpEditData.client.clientShippingAddresses.state =
          shippingAddressFromClientInQuote?.state ??
          tmpClearData.client.clientShippingAddresses.state;
        tmpEditData.client.clientShippingAddresses.countryId =
          shippingAddressFromClientInQuote?.country?.id ??
          tmpClearData.client.clientShippingAddresses.countryId;
        tmpEditData.client.clientShippingAddresses.zip =
          shippingAddressFromClientInQuote?.zip ??
          tmpClearData.client.clientShippingAddresses.zip;
        tmpEditData.client.clientShippingAddresses.attentionTo =
          shippingAddressFromClientInQuote?.attentionTo ??
          tmpClearData.client.clientShippingAddresses.attentionTo;
        tmpEditData.client.clientBillingAddresses.typeAddress = "Shipping";
      }

      if (!!tmpEditData.order.clientCreditCardId) {
        const creditCardFromClientInQuote =
          clientInQuote?.clientCreditCards?.find(
            (el: any) => el.id === tmpEditData.order.clientCreditCardId,
          );

        tmpEditData.client.clientCreditCards.cardNumber =
          creditCardFromClientInQuote?.cardNumber ??
          tmpClearData.client.clientCreditCards.cardNumber;
        tmpEditData.client.clientCreditCards.holderName =
          creditCardFromClientInQuote?.holderName ??
          tmpClearData.client.clientCreditCards.holderName;
      }

      if (!!tmpEditData.client.clientBillingAddresses.id) {
        const billingAddressFromClientInQuote =
          clientInQuote?.shippingAddresses?.find(
            (el: any) => el.id === tmpEditData.client.clientBillingAddresses.id,
          );
        tmpEditData.client.clientBillingAddresses.id =
          billingAddressFromClientInQuote?.id ??
          tmpClearData.client.clientBillingAddresses.id;
        tmpEditData.client.clientBillingAddresses.address =
          billingAddressFromClientInQuote?.address ??
          tmpClearData.client.clientBillingAddresses.address;
        tmpEditData.client.clientBillingAddresses.address2 =
          billingAddressFromClientInQuote?.address2 ??
          tmpClearData.client.clientBillingAddresses.address2;
        tmpEditData.client.clientBillingAddresses.city =
          billingAddressFromClientInQuote?.city ??
          tmpClearData.client.clientBillingAddresses.city;
        tmpEditData.client.clientBillingAddresses.state =
          billingAddressFromClientInQuote?.state ??
          tmpClearData.client.clientBillingAddresses.state;
        tmpEditData.client.clientBillingAddresses.countryId =
          billingAddressFromClientInQuote?.country?.id ??
          tmpClearData.client.clientBillingAddresses.countryId;
        tmpEditData.client.clientBillingAddresses.zip =
          billingAddressFromClientInQuote?.zip ??
          tmpClearData.client.clientBillingAddresses.zip;
        tmpEditData.client.clientBillingAddresses.attentionTo =
          billingAddressFromClientInQuote?.attentionTo ??
          tmpClearData.client.clientBillingAddresses.attentionTo;
        tmpEditData.client.clientBillingAddresses.typeAddress = "Billing";
      }

      if (
        !!tmpEditData.order.clientCreditCardId &&
        !clientInQuote?.clientCreditCards?.find(
          (el: ClientCreditCard) =>
            el.id === tmpEditData.order.clientCreditCardId,
        )
      ) {
        tmpEditData.order.clientCreditCardId =
          tmpClearData.order.clientCreditCardId;
      }

      return tmpEditData;
    })
  }, [setQuoteEditData, clientInQuote, clearQuoteEditData, isLoadingClient]);

  const updateClientCreditCard = useCallback(
    (id: number) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        const tmpClearData = cloneDeep(clearQuoteEditData);

        if (id !== tmpEditData.order.clientCreditCardId) {
          tmpEditData.order.clientCreditCardId = id || 0;
        }

        if (!!tmpEditData.order.clientCreditCardId) {
          const creditCardFromClientInQuote =
            clientInQuote?.clientCreditCards?.find(
              (el: any) => el.id === tmpEditData.order.clientCreditCardId,
            );

          tmpEditData.client.clientCreditCards.cardNumber =
            creditCardFromClientInQuote?.cardNumber ??
            tmpClearData.client.clientCreditCards.cardNumber;
          tmpEditData.client.clientCreditCards.holderName =
            creditCardFromClientInQuote?.holderName ??
            tmpClearData.client.clientCreditCards.holderName;
        }

        return tmpEditData;
      })
    },
    [setQuoteEditData, clearQuoteEditData, clientInQuote],
  );

  const updateClientContactId = useCallback(
    (id: number) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        const tmpClearData = cloneDeep(clearQuoteEditData);

        if (id !== tmpEditData.client.clientContacts.id) {
          tmpEditData.client.clientContacts.id = id || undefined;
        }

        const clientContactFromClientInQuote =
          clientInQuote?.clientContacts?.find(
            (el: any) => el.id === tmpEditData.client.clientContacts.id,
          );

        tmpEditData.client.clientContacts.id =
          clientContactFromClientInQuote?.id ??
          tmpClearData.client.clientContacts.id;
        tmpEditData.client.clientContacts.sex =
          clientContactFromClientInQuote?.sex === "n/a"
            ? tmpClearData.client.clientContacts.sex
            : typeof clientContactFromClientInQuote?.sex === "number"
              ? clientContactFromClientInQuote?.sex
              : tmpClearData.client.clientContacts.sex;
        tmpEditData.client.clientContacts.fax =
          clientContactFromClientInQuote?.fax ??
          tmpClearData.client.clientContacts.fax;
        tmpEditData.client.clientContacts.email =
          clientContactFromClientInQuote?.email ??
          tmpClearData.client.clientContacts.email;
        tmpEditData.client.clientContacts.note =
          clientContactFromClientInQuote?.note ??
          tmpClearData.client.clientContacts.note;
        tmpEditData.client.clientContacts.jobTitle =
          clientContactFromClientInQuote?.jobTitle ??
          tmpClearData.client.clientContacts.jobTitle;
        tmpEditData.client.clientContacts.phone2 =
          clientContactFromClientInQuote?.phone2 ??
          tmpClearData.client.clientContacts.phone2;
        tmpEditData.client.clientContacts.phone1 =
          clientContactFromClientInQuote?.phone1 ??
          tmpClearData.client.clientContacts.phone1;
        tmpEditData.client.clientContacts.lastName =
          clientContactFromClientInQuote?.lastName ??
          tmpClearData.client.clientContacts.lastName;
        tmpEditData.client.clientContacts.firstName =
          clientContactFromClientInQuote?.firstName ??
          tmpClearData.client.clientContacts.firstName;
        tmpEditData.client.clientContacts.birthday =
          clientContactFromClientInQuote?.birthday ??
          tmpClearData.client.clientContacts.birthday;

        return tmpEditData;
      })
    },
    [setQuoteEditData, clearQuoteEditData, clientInQuote],
  );

  const updateShippingAddressId = useCallback(
    (id: number) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        const tmpClearData = cloneDeep(clearQuoteEditData);

        if (id !== tmpEditData.client.clientShippingAddresses.id) {
          tmpEditData.client.clientShippingAddresses.id = id || undefined;
        }

        const shippingAddressFromClientInQuote =
          clientInQuote?.shippingAddresses?.find(
            (el: any) => el.id === tmpEditData.client.clientShippingAddresses.id,
          );
        tmpEditData.client.clientShippingAddresses.id =
          shippingAddressFromClientInQuote?.id ??
          tmpClearData.client.clientShippingAddresses.id;
        tmpEditData.client.clientShippingAddresses.address =
          shippingAddressFromClientInQuote?.address ??
          tmpClearData.client.clientShippingAddresses.address;
        tmpEditData.client.clientShippingAddresses.address2 =
          shippingAddressFromClientInQuote?.address2 ??
          tmpClearData.client.clientShippingAddresses.address2;
        tmpEditData.client.clientShippingAddresses.city =
          shippingAddressFromClientInQuote?.city ??
          tmpClearData.client.clientShippingAddresses.city;
        tmpEditData.client.clientShippingAddresses.state =
          shippingAddressFromClientInQuote?.state ??
          tmpClearData.client.clientShippingAddresses.state;
        tmpEditData.client.clientShippingAddresses.countryId =
          shippingAddressFromClientInQuote?.country?.id ??
          tmpClearData.client.clientShippingAddresses.countryId;
        tmpEditData.client.clientShippingAddresses.zip =
          shippingAddressFromClientInQuote?.zip ??
          tmpClearData.client.clientShippingAddresses.zip;
        tmpEditData.client.clientShippingAddresses.attentionTo =
          shippingAddressFromClientInQuote?.attentionTo ??
          tmpClearData.client.clientShippingAddresses.attentionTo;
        tmpEditData.client.clientBillingAddresses.typeAddress = "Shipping";

        return tmpEditData;
      })
    },
    [setQuoteEditData, clearQuoteEditData, clientInQuote],
  );

  const updateBillingAddressId = useCallback(
    (id: number) => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        const tmpClearData = cloneDeep(clearQuoteEditData);

        if (id !== tmpEditData.client.clientBillingAddresses.id) {
          tmpEditData.client.clientBillingAddresses.id = id || undefined;
        }

        const billingAddressFromClientInQuote =
          clientInQuote?.shippingAddresses?.find(
            (el: any) => el.id === tmpEditData.client.clientBillingAddresses.id,
          );
        tmpEditData.client.clientBillingAddresses.id =
          billingAddressFromClientInQuote?.id ??
          tmpClearData.client.clientBillingAddresses.id;
        tmpEditData.client.clientBillingAddresses.address =
          billingAddressFromClientInQuote?.address ??
          tmpClearData.client.clientBillingAddresses.address;
        tmpEditData.client.clientBillingAddresses.address2 =
          billingAddressFromClientInQuote?.address2 ??
          tmpClearData.client.clientBillingAddresses.address2;
        tmpEditData.client.clientBillingAddresses.city =
          billingAddressFromClientInQuote?.city ??
          tmpClearData.client.clientBillingAddresses.city;
        tmpEditData.client.clientBillingAddresses.state =
          billingAddressFromClientInQuote?.state ??
          tmpClearData.client.clientBillingAddresses.state;
        tmpEditData.client.clientBillingAddresses.countryId =
          billingAddressFromClientInQuote?.country?.id ??
          tmpClearData.client.clientBillingAddresses.countryId;
        tmpEditData.client.clientBillingAddresses.zip =
          billingAddressFromClientInQuote?.zip ??
          tmpClearData.client.clientBillingAddresses.zip;
        tmpEditData.client.clientBillingAddresses.attentionTo =
          billingAddressFromClientInQuote?.attentionTo ??
          tmpClearData.client.clientBillingAddresses.attentionTo;
        tmpEditData.client.clientBillingAddresses.typeAddress = "Billing";

        return tmpEditData;
      })
    },
    [setQuoteEditData, clearQuoteEditData, clientInQuote],
  );

  useEffect(() => {
    setClientData();
  }, [clientInQuote, isNeedUpdateClientData]);

  const getQuote = useCallback(async () => {
    if (!quoteId || isLoadingQuote) return;
    setIsLoadingQuote(true);
    setClientId(undefined);
    setClientInQuote(undefined);
    setIsNeedUpdateClientData(false)
    setQuote(undefined);

    await fetchQuote({ variables: { id: quoteId } })
      .then(async (res) => {
        const { data } = res;

        if (!data) return;

        const { quote } = data;

        if (!quote) {
          return;
        }

        setQuote(quote);
      })
      .finally(() => {
        setIsLoadingQuote(false);
      });

    if (isNeedUpdateQuote) {
      setIsNeedUpdateQuote(false);
    }
  }, [isNeedUpdateQuote, quoteId]);

  useEffect(() => {
    if (isLoadingQuote) return;

    const currentIsNeedUpdateClientData = !(quoteEditData.id === quoteId && !!quoteEditData.client.id);

    setIsNeedUpdateClientData(currentIsNeedUpdateClientData);

    const additionalConditionQuoteUpdate = () => {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);

        const {
          orderProducts,
          quotePaymentLinkTpls,
          quoteOrder
        } = quote;

        if (quoteOrder?.id && !tmpEditData.order.id) {
          tmpEditData.order.id = quoteOrder.id;
          tmpEditData.order.watchTracking1 = quoteOrder.watchTracking1;
        }

        if (paymentTabUpdated) {
          tmpEditData.quotePaymentLinkTpls =
            quotePaymentLinkTpls && !!quotePaymentLinkTpls.length
              ? quotePaymentLinkTpls
              : [];

          setPaymentTabUpdated(false);
        }

        if (productUpdated) {
          tmpEditData.orderProducts = orderProducts
            ? [...orderProducts].sort((a: ProductFromServer, b: ProductFromServer) => {
              const sortA = a.sort
              const sortB = b.sort
              if (sortA < sortB) {
                return -1;
              }
              if (sortA > sortB) {
                return 1;
              }

              // names must be equal
              return 0;
            }).map((el: ProductFromServer) => {
              const prettifyProductOption = (stringValues: string) => {
                const lastIndexSign = stringValues.lastIndexOf("_");

                if (lastIndexSign === stringValues.length - 1) {
                  stringValues = stringValues.slice(0, lastIndexSign);
                }

                return stringValues;
              };

              return {
                id: el.id || undefined,
                description: el.description || "",
                priceTableId: el.priceTable?.id ?? null,
                productId: el.product?.id ?? null,
                productOptionString: el.productOptionsString
                  ? prettifyProductOption(el.productOptionsString)
                  : null,
                quantity: el.quantity ?? null,
                unitPrice: el.unitPrice ?? null,
                isNewProduct: false,
              };
            })
            : [];

          if (tmpEditData.orderProducts.length < 10 && tabQuoteInfoAccess?.forms?.orderProducts?.editable) {
            const emptyEl: ProductEditData[] = Array.from(
              { length: 10 - tmpEditData.orderProducts.length },
              (_, i) => ({
                description: null,
                priceTableId: null,
                productId: null,
                productOptionString: null,
                quantity: null,
                unitPrice: null,
                isNewProduct: true,
              }),
            );

            tmpEditData.orderProducts.push(...emptyEl);
          }

          setProductUpdated(false)
        }

        return tmpEditData;
      })
    }

    if (quote && (!currentIsNeedUpdateClientData || (!!quote.quoteOrder?.id && !quoteEditData.order.id))) {
      additionalConditionQuoteUpdate();
    }

    if (!currentIsNeedUpdateClientData) {
      setClientId(quoteEditData.client.id);
      return;
    }

    if (!quote || !quoteId) {
      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        const tmpClearData = cloneDeep(clearQuoteEditData);

        if (currentIsNeedUpdateClientData) {
          return  tmpClearData
        }

        return tmpEditData;
      })

      return;
    }

    if (quote) {
      const {
        id,
        client,
        user,
        quoteAdditionalRows,
        orderProducts,
        shippingAddress,
        billingAddress,
        leadSource,
        quoteType,
        supplier,
        quoteOrder,
        useInventory,
        requiredDeliveryDate,
        shippingTax,
        withoutTotal,
        withoutTax,
        status,
        clientContact,
        note,
        useAdditionalLine,
        leadSourceStatus,
        quotePaymentLinkTpls,
      } = quote;

      setQuoteEditData(prevState => {
        const tmpEditData = cloneDeep(prevState);
        const tmpClearData =  cloneDeep(currentIsNeedUpdateClientData ? clearQuoteEditData : prevState);


        tmpEditData.id = id ?? undefined;
        tmpEditData.leadSource = leadSource ?? tmpClearData.leadSource;
        tmpEditData.status = status ?? tmpClearData.status;
        tmpEditData.leadSourceStatus =
          leadSourceStatus ?? tmpClearData.leadSourceStatus;
        tmpEditData.userId = user?.id || userId || tmpClearData.userId;
        tmpEditData.supplierId = supplier?.id || tmpClearData.supplierId;
        tmpEditData.useInventory = useInventory || false;
        tmpEditData.useAdditionalLine = useAdditionalLine || false;
        tmpEditData.withoutTax = withoutTax || false;
        tmpEditData.withoutTax = withoutTax || false;
        tmpEditData.requiredDeliveryDate =
          requiredDeliveryDate || tmpClearData.requiredDeliveryDate;
        tmpEditData.quoteAdditionalRows = quoteAdditionalRows?.length
          ? quoteAdditionalRows
          : tmpClearData.quoteAdditionalRows;

        tmpEditData.client.clientContacts.id =
          clientContact?.id ?? tmpClearData.client.clientContacts.id;
        tmpEditData.client.clientShippingAddresses.id =
          shippingAddress?.id ?? tmpClearData.client.clientShippingAddresses.id;
        tmpEditData.client.clientBillingAddresses.id =
          billingAddress?.id ?? tmpClearData.client.clientBillingAddresses.id;

        if (quoteOrder) {
          tmpEditData.order.id = quoteOrder.id ?? tmpClearData.order.id;

          tmpEditData.order.note = quoteOrder.note ?? tmpClearData.order.note;
          tmpEditData.order.specialInstructions =
            quoteOrder.specialInstructions ??
            tmpClearData.order.specialInstructions;
          tmpEditData.order.misc = quoteOrder.misc ?? tmpClearData.order.misc;
          tmpEditData.order.paymentType =
            quoteOrder.paymentType ?? tmpClearData.order.paymentType;
          tmpEditData.order.paymentStatus =
            quoteOrder.paymentStatus ?? tmpClearData.order.paymentStatus;
          tmpEditData.order.shippingMethod =
            quoteOrder.shippingMethod ?? tmpClearData.order.shippingMethod;
          tmpEditData.order.trackingNumber1 =
            quoteOrder.trackingNumber1 ?? tmpClearData.order.trackingNumber1;
          tmpEditData.order.trackingNumber2 =
            quoteOrder.trackingNumber2 ?? tmpClearData.order.trackingNumber2;
          tmpEditData.order.clientCreditCardId =
            quoteOrder?.clientCreditCard?.id ||
            tmpClearData.order.clientCreditCardId;
          tmpEditData.order.useUpsFedexAcct =
            quoteOrder.useUpsFedexAcct ?? tmpClearData.order.useUpsFedexAcct;
          tmpEditData.order.customerPaysShipping =
            quoteOrder.customerPaysShipping ??
            tmpClearData.order.customerPaysShipping;
          tmpEditData.order.watchTracking1 =
            quoteOrder.watchTracking1 ?? tmpClearData.order.watchTracking1;
          tmpEditData.order.watchTracking2 =
            quoteOrder.watchTracking2 ?? tmpClearData.order.watchTracking2;
        }

        tmpEditData.orderProducts = orderProducts
          ? [...orderProducts].sort((a: ProductFromServer, b: ProductFromServer) => {
            const sortA = a.sort
            const sortB = b.sort
            if (sortA < sortB) {
              return -1;
            }
            if (sortA > sortB) {
              return 1;
            }

            // names must be equal
            return 0;
          }).map((el: ProductFromServer) => {
            const prettifyProductOption = (stringValues: string) => {
              const lastIndexSign = stringValues.lastIndexOf("_");

              if (lastIndexSign === stringValues.length - 1) {
                stringValues = stringValues.slice(0, lastIndexSign);
              }

              return stringValues;
            };

            return {
              id: el.id || undefined,
              description: el.description || "",
              priceTableId: el.priceTable?.id ?? null,
              productId: el.product?.id ?? null,
              productOptionString: el.productOptionsString
                ? prettifyProductOption(el.productOptionsString)
                : null,
              quantity: el.quantity ?? null,
              unitPrice: el.unitPrice ?? null,
              isNewProduct: false,
            };
          })
          : [];

        if (tmpEditData.orderProducts.length < 10 && tabQuoteInfoAccess?.forms?.orderProducts?.editable) {
          const emptyEl: ProductEditData[] = Array.from(
            { length: 10 - tmpEditData.orderProducts.length },
            (_, i) => ({
              description: null,
              priceTableId: null,
              productId: null,
              productOptionString: null,
              quantity: null,
              unitPrice: null,
              isNewProduct: true,
            }),
          );

          tmpEditData.orderProducts.push(...emptyEl);
        }

        tmpEditData.withoutTotal = withoutTotal;

        tmpEditData.note = note ?? tmpClearData.note;

        tmpEditData.quotePaymentLinkTpls =
          !!quotePaymentLinkTpls && !!quotePaymentLinkTpls.length
            ? quotePaymentLinkTpls
            : [];

        return tmpEditData;
      })

      if (client?.id) {
        setClientId(client.id);
      }
    }
  }, [quote, isLoadingQuote, quoteId]);

  const [deleteId, setDeleteId] = useState<
    { id: number; type: string } | undefined
  >();
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);

  const onDelete = useCallback(async () => {
    setIsLoadingDelete(true);

    if (deleteId?.type === "quote") {
      await deleteQuote({ variables: { ids: [quoteId] } });
    }
    setIsLoadingDelete(false);
  }, [deleteId]);

  const isValidForms = useMemo(
    () =>
      isValidNonEmpty(quoteEditData.client.accountName) &&
      isValidNonEmpty(quoteEditData.client.clientContacts.email) &&
      isValidEmail(quoteEditData.client.clientContacts.email) &&
      isValidNonEmpty(quoteEditData.client?.clientContacts?.firstName),
    [quoteEditData],
  );

  useEffect(() => {
    if (paymentTabUpdated) {
      setIsNeedUpdateQuote(true)
    }
  }, [paymentTabUpdated]);

  return (
    <section className="layout-content">
      <HeaderWithReactiveValueAndSaveBtn
        className="layout-content__header"
        headerItemList={[
          {
            label: "Quote",
            value: quoteEditData?.id || "",
            isVisible: true,
            name: "accountName",
          },
          {
            label: "Name",
            value:
              (quoteEditData?.client?.clientContacts?.firstName
                ? `${quoteEditData?.client?.clientContacts?.firstName || ""}`
                : "") +
              (quoteEditData?.client?.clientContacts?.lastName
                ? ` ${quoteEditData?.client?.clientContacts?.lastName || ""}`
                : "") +
              (quoteEditData?.client?.company
                ? ` ${quoteEditData?.client?.company || ""}`
                : ""),
            isVisible: true,
            name: "name",
            onClick:
              editClientAccess && clientId
                ? () => {
                  window.open(`/clients/${clientId}`, "_blank");
                }
                : undefined,
          },
        ]}
        additionalButtons={[

          {
            label: "Delete",
            name: "delete",
            color: "warning",
            onClick: () => {
              if (quoteId) {
                setDeleteId({ type: "quote", id: quoteId });
              }
            },
            hidden: !quoteId || !buttonsAccess?.includes("delete") || !hasEditAndDeleteAccess,
          },
          {
            label: "Save",
            name: "save",
            color: "success",
            icon: isLoadingUpdateQuote
              ? "inline-block animate-spin icon-spin4"
              : "icon-floppy",
            disabled: !isValidForms || isLoadingUpdateQuote,
            onClick: () => {
              onSaveQuote(quoteEditData);
            },
            hidden: !buttonsAccess?.includes("save") || !hasEditAndDeleteAccess,
          },
        ]}
      />
      {isLoadingData && <LoadingDesign />}
      {!isLoadingData && (
        <TabsNew
          tabs={tabsList}
          currentTab={currentTab}
          defaultPath={`/quotes/${quoteId ? quoteId : "new_quotes"}`}
        >
          {{
            ["quote_info"]: (
              <EditQuoteQuoteInfo
                stateTax={stateTax}
                onChangeTax={setStateTax}
                quoteEditData={quoteEditData}
                setQuoteEditData={setQuoteEditData}
                setIsNeedUpdateQuote={() => {
                  setIsNeedUpdateQuote(true);
                }}
                setClientId={setClientId}
                tabQuoteInfoAccess={tabQuoteInfoAccess}
                hasEditAndDeleteAccess={hasEditAndDeleteAccess}
                isLoadingData={isLoadingQuote}
                setDeleteId={setDeleteId}
                onUpdateQuotePartial={onSaveQuote}
                isValidForms={isValidForms}
                clientContactsList={clientContactsList}
                setClientContactId={updateClientContactId}
                shippingAddressesList={shippingAddressList}
                billingAddressesList={billingAddressList}
                setBillingAddressId={updateBillingAddressId}
                setShippingAddressId={updateShippingAddressId}
                defaultClientContactId={defaultClientContactId}
                createdNewClientContact={isNewFieldsClientQuote.clientContact}
                roleName={roleName}
                defaultBillingAddressId={defaultBillingAddressId}
                defaultShippingAddressId={defaultShippingAddressId}
                createdNewShippingAddress={isNewFieldsClientQuote.shippingAddress}
                createdNewBillingAddress={isNewFieldsClientQuote.billingAddress}
                clientChanged={!!clientId && quote?.client?.id && clientId !== quote.client.id}
              />
            ),
            ["miscellaneous"]: (
              <EditQuoteMisc
                quoteEditData={quoteEditData}
                setQuoteEditData={setQuoteEditData}
                onUpdateQuotePartial={onUpdateQuotePartial}
                accessModules={accessModules}
                tabMiscAccess={tabMiscAccess}
                userId={userId}
                hasEditAndDeleteAccess={hasEditAndDeleteAccess}
                roleName={roleName}
              />
            ),
            ["shipping_info"]: (
              <EditQuoteShippingInfo
                hasEditAndDeleteAccess={hasEditAndDeleteAccess}
                quoteEditData={quoteEditData}
                setQuoteEditData={setQuoteEditData}
                creditCardList={creditCardList}
                setIsNeedUpdateQuote={() => {
                  setIsNeedUpdateQuote(true);
                }}
                updateClientCreditCard={updateClientCreditCard}
                tabShippingAndAdditionalInfoAccess={tabShippingAndAdditionalInfoAccess}
                quote={quote}
              />
            ),
            ["payment_info"]: (
              <EditQuotePayment
                quote={quoteEditData}
                accessControlStructure={tabPaymentAccess}
                refetchQuote={() => setPaymentTabUpdated(true)}
                stateTax={stateTax}
              />
            ),
            ["artwork"]: (
              <EditQuoteArtWork
                quoteId={quoteId || 0}
                accessModulesTabsArtWork={tabArtWorkAccess}
                accessControlStructure={accessControlStructure}
                hasEditAndDeleteAccess={hasEditAndDeleteAccess}
                userId={userId}
              />
            ),
            ["transaction_logs"]: (
              <EditQuoteTransactionLogs
                quoteId={quoteId || 0}
                quoteEditData={quoteEditData}
              />
            ),
            ["logs"]: <EditQuoteLogs quoteId={quoteId || 0} />,
          }}
        </TabsNew>
      )}
      {!!deleteId && (
        <QuoteDeleteModal
          setShowDeleteModal={() => {
            setDeleteId(undefined);
          }}
          deleteTitle={deleteId.type}
          onDelete={onDelete}
          loading={isLoadingDelete}
        />
      )}
    </section>
  );
};

export default EditQuote;
